import React from "react";
import { GrFormClose } from "react-icons/gr"
import { IoIosArrowBack } from "react-icons/io"
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai"
import LOGO from "../assets/ublLogo3.png";
const MainHeader = ({ back, onBack, onClose, stepper }) => {
    return (
        <div className="formHeader">
            <Box
                // padding="4% 0.1% 5% 5%"
                fontSize="1.35rem"
                fontWeight="500"
                textAlign="center"
                backgroundColor="#ffffff"
                // position={{ sm: "fixed", bs: "fixed", lg: "static" }}
                // width={{ sm: "100%", md: "35%", lg: "100%", xl: "100%" }}
                // borderBottom: '0.2px solid rgba(81, 83, 87, 0.5)',
                display="flex"
                flexDirection="row"
                alignItems='center'
                boxShadow='rgba(33, 35, 38, 0.1) 0px 10px 10px -10px'
                minHeight='90px'
                maxHeight='90px'
                borderTopRadius='20px'

            >
                {
                    back ?
                        <Flex direction='row' alignItems='center' w='100%'>
                            <Flex alignItems='center' ml='3%'>
                                <IoIosArrowBack color="rgba(81, 83, 87, 0.5)" fontSize='16px' onClick={() => onBack('basic')} />
                                <Text fontSize='16px' fontWeight='normal' textColor="rgba(81, 83, 87, 0.5)" onClick={() => onBack('basic')} _hover={{ cursor: 'pointer' }}>Back</Text>
                            </Flex>

                            {/* {stepper == 'menu' ?
                                <div style={{ fontFamily: 'Poppins', color: 'rgba(81, 83, 87, 0.8)', fontSize: '18px', marginLeft: '5%' }}>
                                    Main Menu
                                </div>
                                : null} */}
                        </Flex>
                        :
                        <Box display='flex'
                            flexDirection="row"
                            alignItems='center'>
                            <Image src={LOGO} h='70px' />
                            {/* <div style={{ marginLeft: '20px', fontFamily: 'Poppins', color: '#0480DE' }}>
                        UBL
                    </div> */}
                        </Box>
                }
                <Box marginLeft='auto' marginBottom='auto' marginTop='auto' marginRight='6%' onClick={() => onClose(false)}>
                    <AiOutlineClose fontSize='18px' color="rgba(81, 83, 87, 0.5)" />
                </Box>
            </Box>
        </div>
    )
}

export default MainHeader;