import React, { useState } from "react";
import { Flex, Box, Image, Text, NumberInputField,NumberInput,Select } from "@chakra-ui/react";
import Fade from 'react-reveal/Fade';
import SwipeButton from "../SwipeButton";
import ATMCARD from "../../assets/Animations/debit.gif";
const CardNumber = ({ handleAccountNumber,botMessage }) => {
    const [cnic, setCnic] = useState("");
    const handleSubmit = () => {
        handleAccountNumber(cnic)
    }
    return (
        <Flex direction='column' m='auto' py="5%" pl="7%" pr="10%" bgColor="white" style={{ zoom: '90%', minHeight: '100%' }}>
            <Fade right>
            <Box p={3} bgColor="#f9f9f9" borderRadius="10px">
                    <Image src={ATMCARD} w='400px' h='200px' />
                </Box>
            <Text fontFamily='Poppins' color='#1B1C1E' mt='5%' mb='2%' ml='5%' mr='5%' fontSize='14px'>{botMessage}</Text>
            <NumberInput>
            <NumberInputField opacity='0.4' borderRadius='5px' placeholder="xxxx" ml='5%' w='90%' color='black' _placeholder={{ opacity: 1, color: '#515357', fontSize: '14px' }} _focus={{ border: 'none' }} p='8px' maxLength={4} onChange={(e) => setCnic(e.target.value)} style={{ border: '0.5px solid rgba(81, 83, 87, 0.5)' }} />
            </NumberInput>
            <Select placeholder='Select option' opacity='0.4' borderRadius='5px' ml='2%' w='95%' color='black' _placeholder={{ opacity: 1, color: '#515357', fontSize: '14px' }} _focus={{ border: 'none' }} p='8px' mt='5%'>
                <option value='option1'>Option 1</option>
                <option value='option2'>Option 2</option>
                <option value='option3'>Option 3</option>
            </Select>
            </Fade>
            <Box position='absolute' bottom='5%' w='85%'>
                <SwipeButton func={handleSubmit} disable={cnic.length == 4 ? false : true} title='CONTINUE' />
            </Box>
        </Flex>
    )
}

export default CardNumber;