export const DATA = {
    menu: [
        {
            title: 'Activate Card',
            payload: 'activecard',
        },
        {
            title: 'Account Balance',
            payload: 'accountbalance',
        },
        {
            title: 'Transaction',
            payload: 'transactions',
        },
        {
            title: 'Block Card',
            payload: 'blockcard',
        },
        {
            title: 'Nearest Branch',
            payload: 'nearestbranch',
        },
        {
            title: 'Reset Pin',
            payload: 'resetpin',
        }
    ],
    faq: {
        ublAsaanAccount: [
            {
                question: 'What is UBL Asaan Account ?',
                answer: 'UBL Asaan Account is a current or savings account that can be opened easily with your Computerized/ Smart National Identity Card (CNIC/ SNIC) or Pakistan Origin Card (POC). Simply fill out a brief form by visiting your nearest branch and deposit an initial amount of PKR 100/-.'
            },
            {
                question: 'What are the Special Features of Asaan Account ? ',
                answer: `UBL Asaan Account has the following features: \n

•Option to open a Current or Savings Account \n
•No minimum balance required \n
•No Fees on Debit Card Issuance \n
•No service charges for account opening or closing \n
•No income document required. The account can be opened with your Computerized/ Smart National Identity Card (CNIC/ SNIC) or Pakistan Origin Card (POC)\n`
            },
            {
                question: 'What documents are required for Opening an Asaan Account?',
                answer: 'Visit the nearest branch (use anchor) of UBL with your Computerized/ Smart National Identity Card (CNIC/ SNIC) or Pakistan Origin Card to open an Asaan Account.'
            },
            {
                question: 'What are the rules for an Asaan Account ? ',
                answer: `Following rules are applied on UBL Asaan Account:\n

• Only Resident Pakistanis can open this account\n
• A CNIC holder is entitled to open one UBL Asaan Account (either as single or jointly operated)\n
• An existing UBL Account holder cannot open a UBL Asaan account (in either single or joint account category).`
            },
            {
                question: 'What is my Asaan Account Transaction Limit ?  ',
                answer: `UBL Asaan Account has the following Debit and Credit transaction limit:\n

·        Total Debit per Month: PKR 1,000,000/-\n
·        Total Credit Balance Limit: PKR 1,000,000/-\n
On opening a full- fledged bank account, the limit for Debit & Credit varies. Click here to open an account now. `
            },

            {
                question: 'What are the Exceptions and Limitations for Asaan Account Transactions ?  ',
                answer: `UBL Asaan Account has the following Debit and Credit transaction limit:\n

·        Total Debit per Month: PKR 1,000,000/-\n
·        Total Credit Balance Limit: PKR 1,000,000/-\n
 
However, following exceptions on transactions are allowed:\n
·        Inward Remittances\n
·        Credit of Profit Return on UBL Asaan Saving Account\n
Bank Charges, government taxes or levies from competent authority\n `
            }

        ],

        signature: [
            {
                question: 'What is Chip Signature Debit MasterCard ? ',
                answer: `Exclusively designed and developed for Signature Customers, this card is also a customer's Signature ID.  UBL Chip Signature Debit MasterCard comes with the power and international acceptance of  MasterCard and Cirrus! Your Chip Signature Debit MasterCard has all the convenience and security you  desire and the quality you deserve.
            Chip Signature Debit MasterCard allows Rs. 1,000,000 cash  withdrawal per day through 10,000 ATMs across the country and at more than 1.5 Million ATMs  worldwide. Chip Signature Debit MasterCard comes with an advance shopping limit of Rs. 1,250,000/- per day. Now  you can conveniently pay without worrying about the hassle of carrying cash. 
           `
            },
            {
                question: 'What are the Privileges of being a Signature Priority Banking customer ?',
                answer: `Following are the benefits and privileges of Signature Priority Banking Customer : \n

                • Free Banking Services (As per SOC) \n
                • Joining Gifts (Welcome Pack) \n
                • Free Signature Debit Card with enhanced limits. \n
                • Signature Priority Banking Helpline 0800-99825 \n
                • Free Signature Priority Pass (Access to over 1300 Airport lounges worldwide) \n
                • Enjoy Exclusive Alliances as a Signature Priority customer* \n
                • Seasonal Gifts** \n
                *Alliances available on Signature Priority Banking \n
                ** New Year, Eid celebrations gifts \n
                `
            },
            {
                question: 'How Can I Apply for Signature Debit MasterCard ?',
                answer: `You can visit nearest UBL Signature Branch to open Priority Banking account & apply for Signature Debit  MasterCard`
            },
            {
                question: 'How Can an Existing UBL Customer Apply for Signature Debit MasterCard ?',
                answer: `You can Apply for by : \n
                • Visit nearest UBL Signature Branch \n
                • Call UBL Contact Center at 0800-99825 \n
                • Email us at debitcard@ubl.com.pk \n
                `
            },
            {
                question: 'What is the Fee Structure for Signature Debit MasterCard ?',
                answer: `1.	 ANNUAL FEE \n
                • Basic- Free \n
                • Supplementary- Free \n
• Funds Transfer through ATM Within UBL PKR. 50/- per transaction \n
• Outside UBL PKR. 100/- per transaction\n
2.	CARD REPLACEMENT FEE \n
●	Normal Free \n
3.	 SWITCH TRANSACTION FEE \n
• Withdrawals on 1 Link ATM other than UBL- PKR. 18.75/-- \n
• Withdrawals on other switches- PKR. 18.75/-- \n
4.	 INTERNATIONAL MC USAGE CHARGES \n
• ATM Withdrawal 4.5% of transaction amount\n
 • POS Purchase \n
o Purchase in Currency other than USD and PKR \n
• Purchase in USD and PKR \n
o Free \n
o Otherwise 4.5% of transaction amount \n
5.	INTERNET USAGE \n
• Activation Charge PKR. 0/- ( zero) \n
• Per session charge PKR. 0/- ( zero)\n

3.	 SWITCH TRANSACTION FEE \n
• Withdrawals on 1 Link ATM other than UBL- PKR. 18.75/--\n 
• Withdrawals on other switches- PKR. 18.75/-- \n
4.	 INTERNATIONAL MC USAGE CHARGES \n
• ATM Withdrawal 4.5% of transaction amount\n
 • POS Purchase \n
o Purchase in Currency other than USD and PKR \n
• Purchase in USD and PKR \n
o Free \n
o Otherwise 4.5% of transaction amount \n
5.	INTERNET USAGE \n
• Activation Charge PKR. 0/- ( zero) \n
• Per session charge PKR. 0/- ( zero)\n

                `
            }
        ]
    },
    transaction: [
        { bal: '123', shop: '20,000 received from Mr Ashar acc # 0000XXXX', date: '23/3/22', acc_num: '50000', arrow: true }
        , { bal: '123,000,00', shop: 'foodpanda online purchase', date: '23/3/22', acc_num: '50000' },
        { bal: '123', shop: 'transferred to acc # 1234XXXX', date: '23/3/22', acc_num: '50000' },
        { bal: '123', shop: '20,000 received from Mr Ashar acc # 0000XXXX', date: '23/3/22', acc_num: '50000', arrow: true }
        , { bal: '123,000,00', shop: 'foodpanda online purchase', date: '23/3/22', acc_num: '50000' },
        { bal: '123', shop: 'transferred to acc # 1234XXXX', date: '23/3/22', acc_num: '50000' },
        { bal: '123', shop: '20,000 received from Mr Ashar acc # 0000XXXX', date: '23/3/22', acc_num: '50000', arrow: true }
        , { bal: '123,000,00', shop: 'foodpanda online purchase', date: '23/3/22', acc_num: '50000' },
        { bal: '123', shop: 'transferred to acc # 1234XXXX', date: '23/3/22', acc_num: '50000' },
        { bal: '123', shop: '20,000 received from Mr Ashar acc # 0000XXXX', date: '23/3/22', acc_num: '50000', arrow: true }
        , { bal: '123,000,00', shop: 'foodpanda online purchase', date: '23/3/22', acc_num: '50000' },
        { bal: '123', shop: 'transferred to acc # 1234XXXX', date: '23/3/22', acc_num: '50000' },
        { bal: '123', shop: '20,000 received from Mr Ashar acc # 0000XXXX', date: '23/3/22', acc_num: '50000', arrow: true }
        , { bal: '123,000,00', shop: 'foodpanda online purchase', date: '23/3/22', acc_num: '50000' },
        { bal: '123', shop: 'transferred to acc # 1234XXXX', date: '23/3/22', acc_num: '50000' }
    ]
}