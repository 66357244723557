import React from "react";
import { Box, Flex, IconButton, Input, Image } from "@chakra-ui/react";
import { BsArrowRight } from "react-icons/bs"
import ReactDOMServer from "react-dom/server"
import ArrowIcon from "../../assets/arrow-right-solid.svg";
import zara from "../../assets/zara.png";
let id = 0;

function get(selector, root = document) {
    return root.querySelector(selector);
}
function getid(value) {
    return document.getElementById(value);
}

const ButtonNew = ({ idLocal, text }) => {
    return (
        <Input display='inline-block' maxW='60%' id={'nextStepNutton' + id} name='nextButton' placeholder='Please Enter' mt='5%' opacity='0.4' borderRadius='5px' color='black' _focus={{ border: 'none' }} p='5px' style={{ border: '0.5px solid rgba(81, 83, 87, 0.5)' }} />
    )
}
const NextOptions = (name, idLocal, side, text, setStepper, step) => {
    const htmlString = ReactDOMServer.renderToString(<ButtonNew idLocal={step} />);
    const imgHtml = ReactDOMServer.renderToString(
        <Image className="msg-img" src={zara} ></Image>
    );
    const imgNull = ReactDOMServer.renderToString(<Box display='none'></Box>);
    const msgHTML = `
    <Box class="msg ${side}-msg" id="currentmessage">
    ${side == 'left' ?
            imgHtml
            : imgNull}
    <Box class = "msg-container">
    <Box class="msg-bubble-next">
            <Box class="msg-text">${text}</Box>
            ${htmlString}
    </Box>
    <Box class="msg-info-time-left">${new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, weekday: 'short' })}</Box>
</Box>
</Box>
        `;
    const msgerChat = get(".msger-chat");
    msgerChat.insertAdjacentHTML("beforeend", msgHTML);
    msgerChat.scrollTop += 500;
    const mtext = getid('nextStepNutton' + id);
    mtext.onclick = function () {
        setStepper(step);
        // const bubble =  getid('nextStepNutton'+id);
        // console.log("bubble",mtext)
        mtext.remove();
    };
    id = id + 1;
}

export default NextOptions;