import React from "react";
import ReactDOMServer from "react-dom/server"
import { Box, Flex, Image, Text, Link, Button, Divider } from "@chakra-ui/react";
import Maps from "../Others/Maps";

import zara from "../../assets/zara.png";
// import { Button } from "ui-neumorphism";

let id = 0;

function get(selector, root = document) {
    return root.querySelector(selector);
}
function getid(value) {
    return document.getElementById(value);
}

function removeDiv() {
    const buttonById = document.getElementById(`locationButton${id}`);
    if (buttonById) {
        buttonById.remove();
    }
}

const MapsMessage = (setStepper,handleLocation, location, mylocation,setLocation) => {
    const imgHtml = ReactDOMServer.renderToString(
        <Image className="msg-img" src={zara} ></Image>
    );
    const mapsHtml = ReactDOMServer.renderToString(
        // <div style={{ height: '20vh', width: '100vw' }}>
        // <Maps />
        // </div>
        // <Image src="https://maps.googleapis.com/maps/api/staticmap?center=Berkeley,CA&zoom=14&size=400x400"/>
        <Flex id='maps' direction='column'>
            <img style={{ marginBottom: '5%' }} src={`https://maps.googleapis.com/maps/api/staticmap?center=Albany%2C+NY&zoom=18&scale=2&size=600x300&maptype=roadmap&format=png&key=AIzaSyBDTNYVMLJpthWvBkozOpTHY8uGXmc1Gpg`} alt="Google maps" />
            <Text color="rgba(81, 83, 87, 0.5)" mb='5%'>
                    {location?.address}
                </Text>
            <Button bgColor='whiteAlpha.200' p='2%' w='50%' fontSize='12px' ml='auto' mr='auto' mb='2%' border='0.5px solid rgba(81, 83, 87, 0.5)' id={'locationButton'+id} _hover={{bgColor:'black', color:'white'}} >More Locations</Button>
            <Button bgColor='whiteAlpha.200' p='2%' w='50%' fontSize='12px'  ml='auto' mr='auto'  border='0.5px solid rgba(81, 83, 87, 0.5)' id={'viewLocationButton'+id}  _hover={{bgColor:'black', color:'white'}} >Navigate</Button>
        </Flex>
    );
    const msgHTML = `
          <Box class="msg left-msg" id=${id}>
          ${imgHtml}
          <Box class = "msg-container">
          <Box class="msg-bubble">
            ${mapsHtml}
          </Box>
          <Box class="msg-info-time-left">${new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, weekday: 'short' })}</Box>
        <Box>
          </Box>
        `;
    const msgerChat = get(".msger-chat");
    msgerChat.insertAdjacentHTML("beforeend", msgHTML);
    msgerChat.scrollTop += 500;
    const buttonById = getid(`locationButton${id}`);
    buttonById.onclick = function () {
        buttonById.remove();
        handleLocation(mylocation?.lat, mylocation?.lng, location?.index);
        // const bubble =  getid('nextStepNutton'+id);
        // console.log("bubble",mtext)
    };
    const viewButtonById = getid(`viewLocationButton${id}`);
    viewButtonById.onclick = function () {
        setLocation(location);
        // setStepper('location');
        window.open("https://maps.google.com?q="+location?.lat+","+location?.lng );
    };
    id = id + 1;
}

export default MapsMessage;