import React from "react";
import ReactDOMServer from "react-dom/server"
import { Divider } from "@chakra-ui/react";


let id = 0;
function get(selector, root = document) {
    return root.querySelector(selector);
}
const DividerMessage = () => {
    const messageType = ReactDOMServer.renderToString(
        <div style={{borderTop:'1px solid rgba(81, 83, 87, 0.2)', marginTop:'5%'}}></div>
    );
   const htmlBody =  `
    ${messageType}`
    const msgerChat = get(".msger-chat");
    msgerChat.insertAdjacentHTML("beforeend", htmlBody);
    msgerChat.scrollTop += 500;
    id = id + 1;
}

export default DividerMessage;