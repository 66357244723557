import createReducer from "../store/createReducer";
import * as types from "../action/types";

const INITIAL_STATE = {
  isLoadingStories: false,
};

const chatWidgetReducer = createReducer(INITIAL_STATE, {
  [types.IS_LOADING](state, action) {
    return {
      ...state,
      isLoadingStories: action.isLoading,
    };
  },
  [types.GET_REPLY](state, action) {
    return {
      ...state,
      reply: action.reply,
    };
  },
  [types.GET_REQUEST_CHAT](state, action) {
    return {
      ...state,
      chatSession: action.reply,
    };
  },
});

export default chatWidgetReducer;
