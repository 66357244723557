import React, { useState } from "react";
import OTPInput from "otp-input-react";
import { Flex, Text, Box } from "@chakra-ui/react";
import Fade from 'react-reveal/Fade';
import CircleOtp from "../CircleOtp";
import SwipeButton from "../SwipeButton";
const OtpCollector = ({ handleOtp, handleOTPTimeOut,botMessage }) => {
    const [OTP, setOTP] = useState("");
    function handleChange(OTP) {
        setOTP(OTP);
    }
    const handleSubmit = () => {
        handleOtp(OTP);
    }
    return (
        <Flex direction='column' alignItems='center' minHeight='100%' pt='5%' bg='white' style={{zoom:'90%'}}>
            <Fade right>
                <CircleOtp setStepper={handleOTPTimeOut} size={160} fontSize='41.4847'/>
                <Flex direction='column' mx='18%' my='10%'>
                    <Text fontFamily='Poppins' fontSize='16px' color='#1B1C1E' textAlign='center'>{botMessage}</Text>
                    {/* <Text fontFamily='Poppins' fontSize='20px' color='#1B1C1E' textAlign='center' mt='8%'>03031234567</Text> */}
                </Flex>
                <Flex direction='column' justifyContent='center' alignItems='center' w='100%' mx={{ base: '8%', md: '2.5%', lg: '2.5%' }} 
                 onKeyUpCapture = {(e)=>{
                    if(e.key == 'Enter' && OTP.length == 6){
                        handleSubmit();
                    }
                }}>
                    <OTPInput value={OTP} onChange={handleChange} autoFocus OTPLength={6} otpType="number" disabled={false} inputClassName='inputClassName' />
                    <Flex direction='row' mt='3%'>
                        <Text fontFamily='Poppins' fontSize='14px' color='#515357' textAlign='center'>Didn’t recieve code? &nbsp;</Text>
                        <Text fontFamily='Poppins' fontSize='14px' color='#515357' textAlign='center' fontWeight='semibold'> Resend</Text>
                    </Flex>
                </Flex>
            </Fade>
            <Box position='absolute' bottom='5%' w='85%'>
                <SwipeButton func={handleSubmit} disable={OTP?.length == 6 ? false : true} title='CONTINUE' />
            </Box>
        </Flex>
    )
}

export default OtpCollector;