import React, { useState } from "react";
import { Flex, Box, Text, Button, Grid, GridItem, Image, Checkbox, CheckboxGroup, Stack } from "@chakra-ui/react";

import { Row } from 'antd';

import ActivateCard from '../assets/menuIcons/ActivateCard.png';
import AboutRDA from '../assets/menuIcons/AboutRDA.png';
import AccountBalance from '../assets/menuIcons/AccountBalance.png';
import AccountTransaction from '../assets/menuIcons/AccountTransaction.png';
import BlockCard from '../assets/menuIcons/BlockCard.png';
import NearestBranch from '../assets/menuIcons/NearestBranch.png';
import Talktoanagent from '../assets/menuIcons/Talktoanagent.png';
import PIN from '../assets/menuIcons/reset.png';
import MenuBox from "./Others/MenuBox";

import Account from "../assets/account.png"
import Arrow from "../assets/arrows.png"
import Block from "../assets/block.png"
import Call from "../assets/call.png"
import Location from "../assets/location.png"
import Lock from "../assets/lock.png"
import Bag from "../assets/moneybag.png"
import Tick from "../assets/tick.png"

import { TiInputChecked, } from "react-icons/ti";
import {TbCheckbox} from "react-icons/tb";
import { RiRotateLockLine, RiQuestionAnswerLine, RiLockPasswordLine } from "react-icons/ri";
import { CiBag1 } from "react-icons/ci";
import { BiTransfer } from "react-icons/bi";
import { MdOutlineBlock, MdOutlineLocationOn } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import {SlWallet} from "react-icons/sl";


const WidgetMenu = ({ setMainFlow, setUrdu, setEnglish, english, urdu, handleLang }) => {
    return (
        <Box overflow="scroll" background='#F9F9F9'>
            <Flex direction='column' px="5%" pt="3%"  mb='7%' background='#F9F9F9' >
                {/* <Button onClick={()=>setMainFlow("")}>Close Menu</Button> */}
                <Text my="2%" mb='5%' textColor="#515357" fontWeight="normal">How would you like to get started?</Text>
                <Row gutter={16}>
                    <MenuBox setMainFlow={setMainFlow} title="Activate Card" flow='activecard' Icon={TbCheckbox} />
                    <MenuBox setMainFlow={setMainFlow} title="Reset Pin" flow="resetpin" Icon={RiLockPasswordLine} />
                    <MenuBox setMainFlow={setMainFlow} title="FAQ" flow="faq" Icon={RiQuestionAnswerLine} />
                </Row>
                <Row gutter={16} style={{ marginTop: '5%' }}>
                    <MenuBox setMainFlow={setMainFlow} title="Account Balance" flow='accountbalance' Icon={SlWallet} />
                    <MenuBox setMainFlow={setMainFlow} title="Account Statement" flow="transactions" Icon={BiTransfer} />
                    <MenuBox setMainFlow={setMainFlow} title="Block Card" flow="blockcard" Icon={MdOutlineBlock} />
                </Row>
                <Row gutter={16} style={{ marginTop: '5%' }}>
                    <MenuBox setMainFlow={setMainFlow} title="Nearest Branch" flow='nearestbranch' Icon={MdOutlineLocationOn} />
                    <MenuBox setMainFlow={setMainFlow} title="Talk to an agent" flow='tta' Icon={IoMdCall} />
                </Row>
            </Flex>
            <Box position="absolute" borderTopWidth="1px" bottom='0%' width='100%' pt="1%" pb='5%' mt="1%" overflow="hidden" bgColor="#ffffff" borderBottomRadius="10px" shadow="lg">
                {/* <Text mt='5%' ml="5%" mb="5%" fontSize={{ sm: "16px", md: "12px", lg: "13px", xl: "17px" }} color="#1b1c1e">Please select your preferred language.</Text>
                <Stack spacing={5} direction='row' mx='5%' mt="2%">
                    <Checkbox isChecked={english} textColor="black" _focus={{ border: "none" }} colorScheme="orange" onChange={(e) => { setEnglish(true); setUrdu(false); handleLang("/change_language_en") }}>
                        <Text fontSize={{ sm: "16px", md: "12px", lg: "13px", xl: "17px" }}  >English</Text>
                    </Checkbox>
                    <Checkbox isChecked={urdu} textColor="black" colorScheme="orange" _focus={{ border: 'none' }} onChange={(e) => { setEnglish(false); setUrdu(true); handleLang("/change_language_ru") }}>
                        <Text fontSize={{ sm: "16px", md: "12px", lg: "13px", xl: "17px" }}>Roman Urdu</Text>
                    </Checkbox>
                </Stack> */}
            </Box>
        </Box>

    )
}
export default WidgetMenu;