import React, { useEffect, useState, useRef } from "react";
import { Flex, Text, Box, Link, Button } from "@chakra-ui/react";
import Bounce from 'react-reveal/Bounce';
import { BsFillArrowUpCircleFill, BsFillArrowDownCircleFill } from "react-icons/bs";
import DownloadLink from "react-download-link";
import SwipeButton from "./SwipeButton";
import TansactionButton from "./TransactionButton";
import WALIMA from "../assets/transactions/feedback.pdf";

const TransactionHistory = ({ handleHistory, history, handleAccountCard, transLimit }) => {
    const [data, setData] = useState(history);
    const messagesEndRef = useRef(null);
    useEffect(() => {
        let localData = [];
        for (let index = 0; index < transLimit; index++) {
            const element = history[index];
            localData.push(element);
        }
        setData(localData);
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [transLimit]);
    const onButtonClick = () => {
        if(transLimit == 5){
            window.open("https://ubl-chatbot-poc.s3.amazonaws.com/5.pdf")
        }
        else if(transLimit == 10){
            window.open("https://ubl-chatbot-poc.s3.amazonaws.com/10.pdf")
        }
        else{
            window.open("https://ubl-chatbot-poc.s3.amazonaws.com/15.pdf")
        }
    }
    return (
        <Flex direction='column' minHeight='100%' mt='8%'>
            <Box ref={messagesEndRef}></Box>
            <div>
                <Text fontFamily='Poppins' fontSize='17px' textColor='#7B7B7B' ml='10%' mb='5%' >
                    {`Your Last ${transLimit} transactions are`}
                </Text>
                <Bounce bottom cascade>
                    {
                        data?.map((item, index) =>
                            <Flex direction='column' borderRadius='10px' p='5%' mx="10%" mt="2%" mb='2%' bgGradient='linear(to-l, rgba(255, 103, 150, 1),rgba(255, 140, 121, 1),)'>
                                <Flex direction='row' alignItems='center'>
                                    {item?.arrow ? <BsFillArrowDownCircleFill color="white" fontSize='18px' style={{ marginRight: '5%' }} /> : <BsFillArrowUpCircleFill color="white" fontSize='18px' style={{ marginRight: '5%' }} />}
                                    <Text fontFamily='Poppins' fontSize='18px' color='white' fontWeight='semibold'>
                                        Rs. {`${item?.bal ? item?.bal : '100,000'}`}
                                    </Text>
                                    <Text color='white' fontSize='12px' ml='auto'>{item?.date}</Text>
                                </Flex>
                                <Flex direction='row' fontSize='14px' w='95%' ml='12%' mr='2%'>
                                    <Text color='white'>{item?.shop}</Text>
                                </Flex>
                            </Flex>
                        )}
                </Bounce>
            </div>

            <Flex direction='row' position='absolute' bgColor='white' w="100%" bottom='0.05%' left="0.25%" borderBottomRadius="10px" p="7%" right="0.01%" >
                <Box w="100%" mr='5%'>
                    <TansactionButton type='history' func={onButtonClick} disable={false} title='Download' />
                </Box>
                <Box w="100%">
                    <TansactionButton type='history' func={handleAccountCard} disable={false} title='DONE' />
                </Box>
            </Flex>
        </Flex>
    )
}

export default TransactionHistory;