import React from "react";
import ReactDOMServer from "react-dom/server"
import { Box, Flex, Image, Text, Link } from "@chakra-ui/react";

import zara from "../../assets/zara.png";

let id = 0;

function get(selector, root = document) {
    return root.querySelector(selector);
}
function getid(value) {
    return document.getElementById(value);
}
function formatDate(date) {
    const h = "0" + date.getHours();
    const m = "0" + date.getMinutes();

    return `${h.slice(-2)}:${m.slice(-2)}`;
}
const SimpleMessages = (name, img, side, text, animation) => {
    const messageType = ReactDOMServer.renderToString(
        <Box className="bouncing-loader" id="typing-animation-id">
            <Box></Box>
            <Box></Box>
            <Box></Box>
        </Box>);
    const imgHtml = ReactDOMServer.renderToString(
        <Image className="msg-img" src={zara} ></Image>
    );
    const imgNull = ReactDOMServer.renderToString(<Box display='none'></Box>);
    // const splitText = ReactDOMServer.renderToString(
    //     <Flex direction='column'>
    //         { 
    //                url? null: text?.map(item =>
    //                 <Text mb='5%'>
    //                     {item}
    //                 </Text>)}
    //     </Flex>
    // );
    const msgHTML = `
          <Box class="msg ${side}-msg" id=${animation ? "typing-animation" : id}>
          ${side == 'left' ?
            imgHtml
            : imgNull}
          <Box class = "msg-container">
          <Box class="msg-bubble">
            ${animation ? messageType : `<Box class="msg-text">${text}</Box>`}
          </Box>
          <Box class="msg-info-time-${side}">${new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, weekday: 'short' })}</Box>
        <Box>
          </Box>
        `;
    const msgerChat = get(".msger-chat");
    msgerChat.insertAdjacentHTML("beforeend", msgHTML);
    msgerChat.scrollTop += 500;
    // const mtext = getid(id);
    // mtext.onclick = function () { console.log(text); };
    id = id + 1;
}

export default SimpleMessages;