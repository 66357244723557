import React from "react";
import { Box, Flex, Grid, GridItem, Image } from "@chakra-ui/react";
import Fade from 'react-reveal/Fade';

import JUNAID from '../../assets/junaid.png';

const AgentHandover = () => {

    return (
            <Box className="msg left-msg" mb='15%' mt='15%'>
                <Box className='option'>
                    <Flex direction='row' ml='5%' mr='7%'>
                            <Box w='55%'>
                                <Image src={JUNAID} w='100%' h='100%' m='0%' objectFit='cover' />
                            </Box>
                        <Fade right>
                            <Flex direction='column' bg='#F6F6F6' p='10px' borderRadius='10px' ml='2%' >
                                <Box className="msg-text" textColor='#51535780' mb="5%">Hello, my name is Junaid, I am your customer representative, how may I help you today ?</Box>
                            </Flex>
                        </Fade>
                    </Flex>
                </Box>
            </Box>
    )
}

export default AgentHandover;