import { extendTheme } from '@chakra-ui/react'

const breakpoints = {
    sm: '320px',
    bs:'390px',
    md: '760px',
    lg: '1020px',
    xl: '1290px',
    '2xl': '1536px',
}

export default extendTheme({ breakpoints })
  