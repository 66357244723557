import React, { useEffect, useState } from "react";
import { Flex, Box, Text, Button, Image } from "@chakra-ui/react";
import ZARA from "../assets/zara.png"

const GettingStarted = ({ setStepper }) => {
    const [typing, setTyping] = useState(true);
    const [time, setTime] = useState('');
    useEffect(() => {
        setTimeout(() => setTyping(false), 2000);
        var day = new Date();
        var hr = day.getHours();
        if (hr >= 0 && hr < 12) {
            setTime("Good Morning")
        } else if (hr == 12) {
            setTime("Good Noon")
        } else if (hr >= 12 && hr <= 17) {
            setTime("Good Afternoon")
        } else {
            setTime("Good Evening")
        }
    }, []);
    return (
        <Flex direction='column' ml='5%' position='absolute' bottom='5%'>

            {typing ?
                <Flex direction='row' width='100%' alignItems='baseline'>
                    <Image src={ZARA} w='100px' h='100px' />
                    <Box className="bouncing-loader">
                        <Box></Box>
                        <Box></Box>
                        <Box></Box>
                    </Box>
                </Flex>
                :
                <Box>
                    <Image src={ZARA} w='100px' />
                    <Text w='70%' fontFamily='Poppins' fontSize='20px' color="#FF6E90">
                        {`${time}, I am Zara, your new personal assistant!`}
                    </Text>
                    <Text w='70%' fontFamily='Poppins' fontSize='12px' color='#7B7B7B' mt='5%'>
                        {`I am here to help you with all your queries. Press the "Let's get Started!" button to begin the chat.`}
                    </Text>
                    <Button mt="6%" alignSelf='flex-start' background='transparent' color='#FF6E90' fontFamily='Poppins' fontWeight='normal' fontSize='18px' _hover={{
                        bgGradient: 'linear(to-l, rgba(255, 103, 150, 1),rgba(255, 140, 121, 1),)',
                        textColor: 'white'
                    }}
                        //   _focus={{
                        //     border: 'none',
                        //   }}
                        //   _active={{
                        //       background:'transparent'
                        //   }}
                        boxShadow='rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
                        onClick={() => setStepper('menu')}>Let's get started!</Button>
                </Box>
            }

        </Flex>
    )
}

export default GettingStarted;