import React, { useEffect, useState, useReducer } from "react";
import {Image} from "@chakra-ui/react";
import GoogleMapReact from 'google-map-react';

const Maps = ({location}) => {
    const [latlong, setLatLong] = useState();
    useEffect(()=>{
        console.log("latlong",location)
        setLatLong(location);
    },[location])
    const defaultProps = {
        zoom: 11
    };
    const AnyReactComponent = ({ text }) => <Image src='https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Map_marker.svg/512px-Map_marker.svg.png' width='20px'/>;

    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyBDTNYVMLJpthWvBkozOpTHY8uGXmc1Gpg" }}
            defaultCenter={latlong}
            defaultZoom={defaultProps.zoom}
        >
            <AnyReactComponent
                lat={latlong?.lat}
                lng={latlong?.lng}
                text="My Marker"
            />
        </GoogleMapReact>
    )
}

export default Maps;