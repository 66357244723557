import React, { useState } from "react";
import { Flex, Box, Image, Text, Input, Button } from "@chakra-ui/react";
const SwipeButton = ({title,disable,func,type}) => {
    return (
        <Button onClick={()=>func()} disabled={disable} w='100%' p='7%' textAlign='center' background={type=='history'?'linear-gradient(75.47deg, #006EB6, #005BA2 64.21%)':'linear-gradient(89.78deg, #FF8C79 0.53%, #FF6796 99.79%, rgba(234, 226, 224, 0.7) 99.8%)'} color={type=='history'?'white':'white'}  fontSize='15px'>
            {title}
        </Button>
    )
}

export default SwipeButton;