import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
// import "./styles.css";

const minuteSeconds = 60;
const daySeconds = 86400;

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;

export default function CircleOtp({time,size,fontSize, setStepper}) {
    const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
    const endTime = stratTime + 243248; // use UNIX timestamp in seconds

    const remainingTime = endTime - stratTime;
    const days = Math.ceil(remainingTime / daySeconds);

    const timerProps = {
        isPlaying: true,
        size: size,
        strokeWidth: 6
    };
    // '21.4847px'
    const renderTime = (dimension, time) => {
        return (
            <div style={{}}>
                <div style={{color:'#1B1C1E', fontSize:fontSize, fontFamily: 'Poppins'}}>00:{time}</div>
                {/* <div>{dimension}</div> */}
            </div>
        );
    };
    return (
            <CountdownCircleTimer
                {...timerProps}
                colors={["#FF8C79","#FF6796","#EAE2E0B2"]}
                duration={minuteSeconds}
                initialRemainingTime={60}
                onComplete={(totalElapsedTime) => ({
                    shouldRepeat: remainingTime - totalElapsedTime > 0
                })}
                onUpdate={(e)=>{
                    if(e == 0 && setStepper){
                        setStepper('basic');
                    }
                }}
            >
                {({ elapsedTime, color }) => (
                    <span style={{ color }}>
                        {renderTime("seconds", getTimeSeconds(elapsedTime))}
                    </span>
                )}
            </CountdownCircleTimer>
    );
}
