import React, { useEffect, useState } from "react";
import { Flex, Box, Image, Text, NumberInputField, NumberInput } from "@chakra-ui/react";
import { Calendar } from 'antd';
import Fade from 'react-reveal/Fade';
import ublcard from "../../assets/ublcard.png";
import ATMCARD from "../../assets/DebitCard.png";
import debit from "../../assets/Animations/debit.gif";
import cheque from "../../assets/Animations/gifcheck.gif";
import SwipeButton from "../SwipeButton";
const PinCollector = ({ handlePin, handleExpDate, type,handleAccountNumber,limit, botMessage }) => {
    const [pin, setPin] = useState("")
    const [date, setDate] = useState("")
    const [calendar, setCalendar] = useState(true);
    const [lim,setLim] = useState(limit);
    useEffect(()=>{
        setLim(limit);
    },[limit])
    const onChange = (name, value) => {
        // console.log(value.format('YYYY-MM-DD'), mode);
        if (name == 'date') {
            setDate(value)
        }
        else if (name == 'pin') {
            setPin(value)
        }
    };
    const handleSubmit = () => {
        type == 'account'? handleAccountNumber(pin) : handlePin(pin, date)
    }
    return (
        <Flex direction='column' m='auto' py="5%" pl="7%" pr="10%" bgColor="white" style={{ zoom: '90%', minHeight: '100%' }}>
            <Fade right>
                <Box p={3} bgColor="#f9f9f9" borderRadius="10px">
                    <Image src={type=='account'? cheque : debit} w='400px' h='200px' />
                </Box>
                <Text fontFamily='Poppins' color='#1B1C1E' mt='5%' mb='2%' ml='5%'  mr='5%' fontSize='14px'>{botMessage}</Text>
                <NumberInput>
                <NumberInputField opacity='0.4' borderRadius='5px' placeholder={lim == 4?"xxxx":"xxxxxxxxxxx"} ml='5%' w='90%' color='black' _placeholder={{ opacity: 1, color: '#515357', fontSize: '14px' }} _focus={{border:'none'}}  p='8px' maxLength={lim} onChange={(e) => onChange('pin', e.target.value)} style={{ border: '0.5px solid rgba(81, 83, 87, 0.5)' }} 
                onKeyUpCapture = {(e)=>{
                    if(e.key == 'Enter' && pin.length == lim){
                        handleSubmit();
                    }
                }}
                />
                </NumberInput>  
            </Fade>
            <Box position='absolute' bottom='5%' w='85%'>
            <SwipeButton func={handleSubmit} disable={pin.length == lim ? false : true} title='CONTINUE' />
            </Box>
        </Flex>
    )
}

export default PinCollector;