import React, { useState } from "react";
import { Flex, Text, Box } from "@chakra-ui/react";
import Flip from 'react-reveal/Flip';
import SwipeButton from "./SwipeButton";
const Unavilable = ({stepper}) => {
    const handleSubmit = () => {
        stepper('basic')
    }
    return (
        <Flex direction='column' minHeight='100%' mt='5%'>
            <Flip right>
                <Flex direction='column' alignItems='flex-start' background='#F9F9F9' borderRadius='10px' p='10%' m="10%" mt="4%">
                    <Text fontFamily='Poppins' fontSize='16px' color='#515357' mt='10%'>
                        Sorry! Currently this feature is only avilable on whatsapp.
                    </Text>
                </Flex>
            </Flip>
            <Box position='absolute' bottom='5%' w='85%' ml='7%'>
                <SwipeButton func={handleSubmit} title='CONTINUE' />
            </Box>
        </Flex>
    )
}

export default Unavilable;