import React, { useCallback } from "react";
import { ChakraProvider } from '@chakra-ui/react';
// import Particles from 'react-particles-js';
import { PageHeader, Button, Avatar } from 'antd';
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import theme from "./assets/theme"
import mainbackground from "../src/assets/mainbackground.png"

import ChatWidget from "./pages/chatWidget";
import "./App.css";
import "antd/dist/antd.css";
import LOGO from "./assets/ublLogo2.png";

function App() {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);

    return (<>
        <ChakraProvider theme={theme}>
            {/* <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: "#0d47a1",
                    },
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 0,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    collisions: {
                        enable: true,
                    },
                    move: {
                        directions: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 6,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 80,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true,
            }}
        /> */}
      
            {/* <div style={{ width: '100%', height: '100vh', backgroundImage: `url(https://iili.io/HKXOA5N.png)`, backgroundRepeat:'no-repeat', backgroundSize:'contain', backgroundPosition:'center', backgroundAttachment:'fixed', WebkitBackgroundSize:'30% 50%'}}> */}
                <ChatWidget />
            {/* </div> */}
        </ChakraProvider>
    </>
    );
}

export default App;
