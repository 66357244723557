import React from "react";
import { Box, Flex, Grid, GridItem, Image, Button, Text } from "@chakra-ui/react";
import { BsArrowRight } from "react-icons/bs"
import SimpleMessages from "./SimpleMessages";
import ReactDOMServer from "react-dom/server";

import zara from "../../assets/zara.png";

let id = 0;

function get(selector, root = document) {
    return root.querySelector(selector);
}
function getid(value) {
    return document.getElementById(value);
}
//   msger-chat
function insertHTML(classname, html) {
    const msgerChat = get(classname);
    msgerChat.insertAdjacentHTML("beforeend", html);
    msgerChat.scrollTop += 500;
}

const FaqsMessageCard = (q,a, func, img, faqName, msg) => {
    const imgString = ReactDOMServer.renderToString(<Image src={img} w='400px' h='180px' mb='5%'></Image>);
    const button = ReactDOMServer.renderToString(
        <Flex direction='column'>
            {/* <Flex direction='row' >
                <Text textColor='#515357' fontSize='14px' mr='2%'>
                    Q:
                </Text>
                <Text textColor='#515357' fontSize='14px'>
                    {q}
                </Text>
            </Flex> */}
            {/* {a?.map(item=>
                <Text ml='7%' textColor='#888888' fontSize='12px' mt='2%'>
                {item}
            </Text>)} */}
            <Button id={'faq' + id} background='#FFFFFF' border='1px solid #DADADA' borderRadius='11px' p='3%' color='#888888' m='5%'>View FAQs</Button>
        </Flex>
    );
    const imgHtml = ReactDOMServer.renderToString(
        <Image className="msg-img" src={zara} ></Image>
    );
    const msgHTML = `   
          <Box class="msg left-msg">
          ${imgHtml}
          <Box class='option'>
            <Box class="msg-bubble">
                ${imgString}
                ${a}
                ${button}
            </Box>
                <Box class="msg-info-time-left">${new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, weekday: 'short' })}</Box>
          </Box>
        </Box>
        `;
    insertHTML(".msger-chat", msgHTML);
    const mtext = getid('faq' + id);
    mtext.onclick = function () {
        // const bubble = getid("msg-bubble-options-main");
        // bubble.remove();
        func(faqName,msg);
    };
    id = id + 1;
}

export default FaqsMessageCard;