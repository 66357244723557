import React, { useState } from "react";
import { Flex, Box, Image, Text, Input, Button } from "@chakra-ui/react";
const TansactionButton = ({title,disable,func,type}) => {
    return (
        <Button onClick={()=>func()} disabled={disable} w='100%' p='8%' textAlign='center' bgGradient='linear(to-l,  rgba(255, 103, 150, 1),rgba(255, 140, 121, 1),)' textColor="white"  fontSize='15px'>
            {title}
        </Button>
    )
}

export default TansactionButton;