import React,{useEffect,useRef} from "react";
import { Flex, Box } from "@chakra-ui/react";


const ChatContainer = ({ setUserMessage, userMessage }) => {
    return (
        <Box>
            <Box  className={"msger"}>
                <Box className={"msger-chat"} id='moiz' overflow='hidden'>
                    <Box className="msg left-msg" borderBottomLeftRadius="0px">
                    </Box>
                    <Box className="msg right-msg">
                    </Box>
                </Box>
            </Box>
            {/* <Flex direction='row'>
                <input style={{ width: '70%' }} onChange={(e) => setUserMessage(e.target.value)} />
                <button style={{ width: '30%' }} onClick={() => SimpleMessages('user', '', 'left', userMessage, '')}>Send</button>
            </Flex> */}
        </Box>
    )
}

export default ChatContainer;