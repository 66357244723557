import React, { useEffect, useState, useRef } from "react";
import { AiOutlineSend } from 'react-icons/ai';
import { TbSend } from 'react-icons/tb'
import { HiMenu } from 'react-icons/hi';
import { Flex, Box, Input, IconButton, Button, Image, Textarea, useMediaQuery, Divider, Link } from "@chakra-ui/react";
import axios from "axios";
import Flip from 'react-reveal/Flip';
import Confetti from 'react-confetti';

import useWindowSize from 'react-use/lib/useWindowSize';
import { v4 as uuidv4 } from 'uuid';

import {
    ChatContainer, GettingStarted, WidgetMenu, SimpleMessages, OptionsMessage, PinCollector, SwipeButton, OtpCollector, NextOptions, BalanceHistory, CnicCollector, CardNumber, ExpCollector, TransactionHistory, Unavilable, AtmPin, MainHeader, DiversionMessage, DividerMessage,
    FaqDetails,
    FaqsMessageCard,
    TalkToAgent,
    AgentHandover,
    Location,
    MapsMessage,
    CarouselMessages,
    DirectorsDetails
} from "../../components";
import { DATA } from "../../constants"
import { borderColor } from "@mui/system";
import ZARASTANDING from "../../assets/zarastand.svg";

const OPTIONS = {
    stuck: {
        options: [
            {
                title: 'No',
                payload: 'basic'
            },
            {
                title: 'Yes',
                payload: 'menu'
            },
        ],
        text1: 'Seems like you are stuck!',
        text2: 'Would you like to continue with your inquiry'
    }
}
let myuuid = uuidv4();

const ChatScreenContainer = ({ setUblWidget }) => {
    const [isLessThan800] = useMediaQuery('(max-height: 800px)')
    const messagesEndRef = useRef(null);
    const { width, height } = useWindowSize();
    const [poper, setPoper] = useState(false);
    const [myMessage, setMyMessage] = useState("");
    const [stepper, setStepper] = useState('started')
    const [userMessage, setUserMessage] = useState("");
    const [pin, setPin] = useState("");
    const [cnic, setCnic] = useState("");
    const [number, setNumber] = useState("");
    const [otp, setOtp] = useState("");
    const [date, setDate] = useState("");
    const [mainFlow, setMainFlow] = useState("");
    const [flow, setFlow] = useState(false);
    const [botReply, setBotReply] = useState("");
    const [mes, setMes] = useState();
    const [english, setEnglish] = useState(true);
    const [urdu, setUrdu] = useState(false);
    const [help, setHelp] = useState(false);
    const [agentHandover, setAgentHandover] = useState(false);
    const [helpOption, setHelpOption] = useState(OPTIONS?.stuck);
    const [inputNextScreen, setInputNextScreen] = useState();
    const [faqsIntent, setFaqsIntent] = useState();
    const [faqsType, setFaqsType] = useState();
    const [accNumLimit, setAccNumLimit] = useState(4);
    const [transLimit, SetTransLimit] = useState(5);
    const [location, setLocation] = useState({});
    const [mylocation, setMyLocation] = useState({});
    const [botMessage, setBotMessage] = useState();
    const [carouselData, setCarouselData] = useState([]);
    const [directors, setDirectors] = useState({});
    let faqsvar = '';
    useEffect(() => {
        getid('moiz');
        // console.log("maa chod",JSON.parse("itemtext"))
        // OptionsMessage('moiz',['Balance Inquiry','Mini Statement','Monthly ','Half-Yearly','Yearly'],'left','How would you like to get started?')
    }, [mes]);
    function getName(value) {
        return document.getElementsByName(value).forEach((item) => item.remove());
    }
    function removeAnimation() {
        const bubble = document.getElementById("typing-animation");
        if (bubble) {
            bubble.remove();
        }
    }
    const handleSeeMore = (text,img) => {
        setDirectors({info:text,image:img});
        setStepper('directors');
    }
    let buttonCheck = true;
    const handleMessages = async (message) => {
        const params = {
            "sender": myuuid,
            "message": message
        }
        buttonCheck = true
        setHelp(false);  // if help portion is still in view
        setAgentHandover(false) // if agenthandover true
        setInputNextScreen(undefined); // enable input field
        getName('nextButton'); // remove if input still exists
        setCarouselData([]);
        if (message == 'otherMenu') {
            setFlow(false);
        }
        else {
            if (message != '/restart') {
                SimpleMessages('', '', 'left', "", true);
            }
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
            // https://40de-2400-adcc-140-6700-bdf8-3d96-9d95-ca49.ngrok.io/webhooks/rest/webhook/
            const res = await axios
                .post(`https://chatbot.fintra.ai/core/webhooks/rest/webhook/`, params)
                .then(function (response) {
                    removeAnimation()
                    const result = response?.data;
                    // console.log("resp", result)
                    result.map(resultMessage => {
                        // console.log("resultMessage", resultMessage)
                        // if(resultMessage.length == 3){
                        //     let type;
                        //     try {
                        //         type = JSON.parse(resultMessage[1].image);
                        //         OptionsMessage('moiz', resultMessage[0].buttons, 'left', resultMessage[0].text, handleMessages, type.image,type.link);
                        //     } catch (e) {
                        //         console.log("error", e)
                        //     }
                        //     faqsvar = resultMessage[2].product;
                        //     setFaqsIntent(resultMessage[2].product);
                        //     console.log("resultMessage[2].product",resultMessage[2].product)
                        // }
                        // else{ 
                        resultMessage.map(item => {
                            // faqsvar = resultMessage[resultMessage.length-1].product;
                            // faqsvar = item.product;
                            if (item?.intent == 'telling_accountType' || item?.intent == 'faq' || item?.intent == 'product_intro_eng' || item?.intent == 'president_ubl') {
                                let types;
                                try {
                                    types = JSON.parse(resultMessage[1].image);
                                    if(item?.intent == 'president_ubl'){
                                        OptionsMessage('moiz', resultMessage[0].buttons, 'left', resultMessage[0].text, handleMessages, types.image, types.link, handleSeeMore);
                                    }
                                    else{
                                        OptionsMessage('moiz', resultMessage[0].buttons, 'left', resultMessage[0].text, handleMessages, types.image, types.link,null);
                                    }
                                    buttonCheck = false;
                                } catch (e) {
                                    console.log("error", e)
                                }
                            }
                            else {
                                if (item?.buttons) {
                                    // if (item?.text == 'These are the credit card Faqs which one would you like to know more about') {
                                    //     const newOpt = item?.buttons
                                    //     newOpt.push({ title: 'Others', payload: 'otherMenu' })
                                    //     OptionsMessage('moiz', newOpt, 'left', item?.text, handleMessages, true);
                                    // }
                                    // else if (item?.text == 'These are the signature card Faqs which one would you like to know more about') {
                                    //     const newOpt = item?.buttons
                                    //     newOpt.push({ title: 'Others', payload: 'otherMenu' })
                                    //     OptionsMessage('moiz', newOpt, 'left', item?.text, handleMessages, true);
                                    // }
                                    let types;
                                    try {
                                        types = JSON.parse(resultMessage[1].image);
                                    } catch (e) {
                                        console.log("error", e)
                                    }
                                    if (!types) {
                                        if (item?.text.search("Card") >= 0) {
                                            OptionsMessage('moiz', item?.buttons, 'left', item?.text, handleMessages, true);
                                        }
                                        else {
                                            OptionsMessage('moiz', item?.buttons, 'left', item?.text, handleMessages, false);
                                        }
                                    }
                                }
                                else if (item?.text) {
                                    let type;
                                    try {
                                        type = JSON.parse(item?.text)
                                    } catch (e) {
                                        console.log("error", e)
                                    }
                                    if (type) {
                                        if (type.type == 'acc_num') {
                                            nextMessage(type.text, 'acc_num');
                                            setAccNumLimit(4);
                                        }
                                        else if (type.type == 'acc_num_11') {
                                            nextMessage(type.text, 'acc_num');
                                            setAccNumLimit(11);
                                        }
                                        else if (type.type == 'pin') {
                                            nextMessage(type.text, 'atmpin')
                                        }
                                        else if (type.type == 'debit_card') {
                                            nextMessage(type.text, 'pin')
                                        }
                                        else if (type.type == 'info') {
                                            setBotReply({ account: type.acc_num, amount: type.bal });
                                            setStepper('accountinfo');
                                        }
                                        else if (type.type == 'exp_date') {
                                            nextMessage(type.text, 'exp')
                                        }
                                        else if (type.type == 'otp') {
                                            nextMessage(type.text, 'otp')
                                        }
                                        else if (type.type == 'cnic') {
                                            nextMessage(type.text, 'cnic')
                                        }
                                        else if (type.type == 'success') {
                                            SimpleMessages('', '', 'left', type.text);
                                            setPoper(true);
                                            setTimeout(() => setPoper(false), 5000);
                                        }
                                        else if (type.type == 'noTransactions') {
                                            setBotReply({ acc_num: type.acc_num, bal: type.bal, shop: type.shop, date: type.date });
                                            SetTransLimit(type.text);
                                            setStepper('noTransactions');
                                            // setStepper('noTransactions');
                                        }
                                        else if (type.type == 'stuck') {
                                            setHelp(true);
                                        }
                                        else if (type.type == 'location') {
                                            getMyLocation();
                                        }
                                        else if (type.type == 'carousel') {
                                            // console.log("carousel",type)
                                            setCarouselData(type.data);
                                            // CarouselMessages(type.data);
                                        }
                                        setBotMessage(type.text);
                                    }
                                    else {
                                        // const textLocal = item?.text.replace(/\n/gm, <br/>);
                                        // const textLocal = item?.text.split('\n');
                                        const textLocal = item?.text.replaceAll('\n','<br/><br/>');
                                        //    console.log(item?.text.split('\n'))
                                        setBotReply(textLocal)
                                        if (resultMessage[resultMessage.length - 1]?.product == 'chip') {
                                            FaqsMessageCard(message, textLocal, handleFAQCard, 'https://ubl-chatbot-poc.s3.amazonaws.com/signature_mastercard.jpg', 'chip', textLocal);
                                        }
                                        else if (resultMessage[resultMessage.length - 1]?.product == 'asan') {
                                            FaqsMessageCard(message, textLocal, handleFAQCard, 'https://ubl-chatbot-poc.s3.amazonaws.com/ubl_asaan.jpg', 'asan', textLocal)
                                        }
                                        else {
                                            const linkMessage = detectURLs(textLocal)
                                            if (linkMessage) {
                                                SimpleMessages('', '', 'left', linkMessage);
                                            }
                                            else {
                                                let typesImage;
                                                try {
                                                    typesImage = JSON.parse(resultMessage[1]?.image);
                                                    if (!typesImage) {
                                                        SimpleMessages('', '', 'left', textLocal);
                                                    }
                                                } catch (error) {
                                                    SimpleMessages('', '', 'left', textLocal);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        });
                        // }
                    })
                })
                .catch(function (error) {
                    console.log(error);
                    removeAnimation()
                });
        }
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    function detectURLs(message) {
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        if(message.match(urlRegex)){ 
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        return message.replace(urlRegex, function (url) {
            var hyperlink = url;
            if (!hyperlink.match('^https?:\/\/')) {
                hyperlink = 'http://' + hyperlink;
            }
            return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
        });
    }
    else {
        return undefined;
    }
    }

    const handleLang = async (message) => {
        const params = {
            "sender": myuuid,
            "message": message
        }
        const res = await axios
            .post(`https://chatbot.fintra.ai/core/webhooks/rest/webhook/`, params)
            .then(function (response) {
                console.log("lang changed")
            })
            .catch(function (error) {
                console.log(error);
            });
    }
     let localLoc = {};
    const handleLocation = async (lat, lng,index) => {
        if (index == 0){
            localLoc = {
                lat,lng
            }
        }
        const params = {
            "lat": lat,
            "long": lng,
            index : index
        }
        SimpleMessages('', '', 'left', "", true);
        const res = await axios
            .post(`https://chatbot.fintra.ai/core/nearest_location`, params)
            .then(function (response) {
                const data = response.data;
                const loc = {
                    lat: data.lat,
                    lng: data.long,
                    branch_name: data.branch_name,
                    address: data.address,
                    index: data.index
                }
                // setStepper('location');
                setLocation(loc);
                removeAnimation();
                // SimpleMessages("","",'left',["Nearest branch has been showed"]);
                MapsMessage(setStepper, handleLocation, loc,localLoc, setLocation);
            })
            .catch(function (error) {
                console.log(error);
                removeAnimation();
            });
    }

    const nextMessage = (text, step) => {
        // NextOptions('moiz', '', 'left', text, setStepper, step);
        SimpleMessages('', '', 'left', text);
        setInputNextScreen(step);
        setStepper(step);
    }
    const widgetStep = (id) => {
        setStepper(id);
        // setChatScreen(true);
    }
    function get(selector, root = document) {
        return root.querySelector(selector);
    }
    function getid(value) {
        const box = document.getElementById(value);
        const allChildren = box.getElementsByTagName('*').length;
        // console.log(allChildren);
        setMes(allChildren);
        return allChildren;
    }
    const handleActiveCard = (option) => {
        SimpleMessages('moiz', '', 'right', option);
        if (mainFlow == 'transactions') {
            NextOptions('moiz', '', 'left', 'Please select how many transactions you may require from the below options', setStepper, 'accountinfo');
        }
        else {
            NextOptions('moiz', '', 'left', 'Enter your Debit card details', setStepper, 'pin');
        }
    }
    const handleBlockCard = (option) => {
        SimpleMessages('moiz', '', 'right', option);
        NextOptions('moiz', '', 'left', 'Enter your card details', setStepper, 'accountnumber');
    }
    const handlePin = (pin, date) => {
        // if (mainFlow == 'activecard') {
        //     SimpleMessages('moiz', '', 'right', 'Atm Card Added');
        //     handleMessages('I have the following 4 digits on my debit card: ' + pin);
        // }
        // else if (mainFlow == 'activecard') {
        //     SimpleMessages('moiz', '', 'right', 'Atm Card Added');
        //     handleMessages('I have the following 4 digits on my debit card: ' + pin);
        // }
        // else if (mainFlow == 'accountbalance') {
        //     SimpleMessages('moiz', '', 'right', 'Account Added');
        //     handleMessages('My account number has the following last 4 digits ' + pin);
        // }
        // else if (mainFlow == 'blockcard') {
        //     SimpleMessages('moiz', '', 'right', 'Card Added');
        //     handleMessages("My debit card's last four digits are" + pin);
        // }
        // else if (mainFlow == 'transactions') {
        //     SimpleMessages('moiz', '', 'right', 'Card Added');
        //     handleMessages("My debit card's last four digits are " + pin);
        // }
        console.log("pinnnnnn");
        if (urdu) {
            SimpleMessages('moiz', '', 'right', 'apka ATM card masool ho chuka ha');
            handleMessages(pin);
        }
        else if (english) {
            SimpleMessages('moiz', '', 'right', 'ATM CARD ADDED');
            handleMessages(pin);
        }

        setStepper('basic');
        // setPin(pin);
        // setDate(date);
        // if (mainFlow == 'activecard' || mainFlow == 'resetpin') {
        //     SimpleMessages('moiz', '', 'left', 'Debit Card details added successfully');
        //     NextOptions('moiz', '', 'left', 'Enter the OTP number sent to your registered number', setStepper, 'otp');
        //     setStepper('basic')
        // }
        // else if (mainFlow == 'accountbalance') {
        //     setStepper('accountinfo');
        // }
        // else if (mainFlow == 'transactions') {
        //     SimpleMessages('moiz', '', 'left', 'Please select how many transactions you may require from the below options');
        //     OptionsMessage('moiz', ['Last 5 Transactions', 'Last 10 Transactions', 'Last 15 Transactions'], 'left', 'How would you like to get started?', handleActiveCard, false);
        //     setStepper('basic');
        // }
    }
    const handleExpDate = (date) => {
        // if (mainFlow == 'activecard') {
        //     SimpleMessages('moiz', '', 'right', 'Expiry Date Added');
        //     handleMessages('My card expires on ' + date);
        // }
        if (urdu) {
            SimpleMessages('moiz', '', 'right', 'Expiry Date Added');
            handleMessages(date);
        }
        else if (english) {
            SimpleMessages('moiz', '', 'right', 'EXPIRY DATE ADDED');
            handleMessages(date);
        }

        setStepper('basic');
    }
    const handleOtp = (otp) => {
        setOtp(otp);
        // if (mainFlow == 'activecard') {
        //     // SimpleMessages('moiz', '', 'left', 'Your card has been activated!');
        //     handleMessages('otp ' + otp);
        // }
        // else if (mainFlow == 'resetpin') {
        //     SimpleMessages('moiz', '', 'left', 'OTP verified');
        //     NextOptions('moiz', '', 'left', 'Enter your new ATM Pin', setStepper, 'cnic');
        // }
        // if (mainFlow == 'transactions') {
        //     // SimpleMessages('moiz', '', 'left', 'OTP Verified successfully.');
        //     // NextOptions('moiz', '', 'left', 'Please enter the last four digits “XXXX” of your ATM Card.', setStepper, 'pin');
        //     handleMessages('otp'+otp);
        // }
        if (urdu) {
            SimpleMessages('moiz', '', 'right', 'OTP');
            handleMessages(otp);
        }
        else if (english) {
            SimpleMessages('moiz', '', 'right', 'OTP');
            handleMessages(otp);
        }
        setStepper('basic');

    }

    const handleAccountCard = () => {
        // SimpleMessages('moiz', '', 'left', 'Flow Ended');
        setStepper('basic');
        // setHelp(true);
        // setHelpOption({ options: OPTIONS?.stuck.options, text1: 'Congratulations! you have seen your Information', text2: OPTIONS?.stuck.text2 })

    }
    const handleRDA = (item) => {
        SimpleMessages('moiz', '', 'right', item);
        if (mainFlow != 'tta') {
            SimpleMessages('moiz', '', 'left', 'RDA answers');
        }
    }
    const handleCnic = (cnic) => {
        setCnic(cnic);
        const formateCnic = cnic.replace(/-/g, "");
        console.log("gtgt", formateCnic)
        if (urdu) {
            SimpleMessages('moiz', '', 'right', 'apka CNIC masool ho chuka ha');
            handleMessages(formateCnic);
        }
        else if (english) {
            SimpleMessages('moiz', '', 'right', 'CNIC ADDED');
            handleMessages(formateCnic);
        }
        setStepper('basic');
        // if (mainFlow == 'transactions') {
        //     SimpleMessages('moiz', '', 'left', 'CNIC details added successfully.');
        //     // NextOptions('moiz', '', 'left', 'Enter the OTP number sent to your registered number', setStepper, 'otp');
        //     handleMessages("My CNIC's last 4 digits are " + cnic);
        //     setStepper('basic');
        // }
        // else if (mainFlow == 'resetpin') {
        //     SimpleMessages('moiz', '', 'left', 'Success Your Pin has been set');
        //     setStepper('basic');
        // }
        // else if (mainFlow == 'accountbalance') {
        //     SimpleMessages('moiz', '', 'right', 'CNIC added');
        //     handleMessages("My CNIC's last 4 digits are " + cnic);
        //     setStepper('basic');
        // }
    }
    const handleAccountNumber = (number) => {
        setNumber(number);
        if (urdu) {
            handleMessages(number);
        }
        else if (english) {
            handleMessages(number);
        }
        setStepper('basic');
        // if (mainFlow == 'blockcard') {
        //     SimpleMessages('moiz', '', 'left', 'Card Blocked');
        //     setStepper('basic');
        // }
    }
    const handleAtmPin = (number) => {
        console.log("cacc")
        if (urdu) {
            handleMessages(number);
        }
        else if (english) {
            handleMessages(number);
        }
        setStepper('basic');
    }
    const handleFlowMain = (text) => {
        setMainFlow(text);
        setFlow(true);
        // setChatScreen(true);
        setStepper('basic');
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
        if (text == 'activecard') {
            // OptionsMessage('moiz', ['Debit card block', 'Debit card activation'], 'left', 'How would you like to get started?', handleActiveCard, true);
            SimpleMessages('moiz', '', 'right', 'Activate Card');
            handleMessages(english ? '/activate_card' : '/activate_card');
        }
        else if (text == 'faq') {
            // OptionsMessage('moiz', ['RDA features', 'RDA Tutorial Video', 'Open Roshan Digital Account', 'Find about RDA Account Opening Process', 'Find about RDA Eligibility Criteria', 'Know about Documents Required for RDA'], 'left', 'To know more please ask a question or you may choose the option number listed below.', handleRDA, false);
            SimpleMessages('moiz', '', 'right', "FAQ's");
            handleMessages('/faq');
        }
        else if (text == 'accountbalance') {
            SimpleMessages('moiz', '', 'right', 'Balance Inquiry');
            handleMessages(english ? '/balance_inquiry' : '/balance_inquiry');
        }
        else if (text == 'transactions') {
            SimpleMessages('moiz', '', 'right', 'Account Statement');
            handleMessages(english ? '/account_statement_inquiry' : '/account_statement_inquiry');
            // SimpleMessages('moiz', '', 'right', 'Mini Statement');
            // NextOptions('moiz', '', 'left', 'Please enter the last four digits “XXXX” of your CNIC', setStepper, 'cnic');
        }
        else if (text == 'blockcard') {
            SimpleMessages('moiz', '', 'right', 'Block Card');
            handleMessages(english ? '/card_block' : '/card_block');
            // OptionsMessage('moiz', ['Debit card block', 'Credit card block', 'Block all cards'], 'left', 'Select the card you want to block?', handleBlockCard, true);
        }
        else if (text == 'nearestbranch') {
            getMyLocation();
            setFlow(false);
        }
        else if (text == 'tta') {
            // OptionsMessage('moiz', ['Call an agent', 'Message an agent'], 'left', 'How would you like to get started?', handleRDA, false);
            setStepper('tta');
            setFlow(false);
            setHelpOption({
                options: [
                    {
                        title: 'Whatsapp',
                        payload: 'tta'
                    },
                    {
                        title: 'Stay here',
                        payload: 'here'
                    }
                ],
                text1: "",
                text2: OPTIONS.stuck.text2 + ' on:'
            });
            setHelp(true);
        }
        else if (text == 'resetpin') {
            // OptionsMessage('moiz', ['Send OTP'], 'left', 'How would you like to get started?', handleActiveCard, true);
            // setStepper('NA');
            SimpleMessages('moiz', '', 'right', 'Reset Pin');
            handleMessages(english ? '/atmPin_reset' : '/atmPin_reset');
        }
    }

    const handleMenuButton = () => {
        if (stepper == 'menu') {
            setStepper('')
        }
        else {
            setStepper('menu')
        }
        setHelp(false);
    }
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    })
    useEffect(() => {
        if (stepper != 'basic') {
            setAgentHandover(false);
        }
    }, [stepper])

    const handleBack = (step) => {
        setStepper((prev) => {
            console.log("prev", prev)
            if (prev == 'tta') {
                setHelp(false);
            }
            else if (prev != 'menu' && prev != 'noTransactions' && prev != 'faqs' && prev != 'accountinfo' && prev != 'NA' && prev != 'location' && prev != 'directors') {
               const tit = DATA?.menu?.find(x => x?.payload === mainFlow)?.title;
                setHelpOption({
                    options: [
                        {
                            title: 'Yes',
                            payload: prev
                        },
                        {
                            title: 'No',
                            payload: 'no'
                        }
                    ],
                    text1: OPTIONS?.stuck?.text1,
                    text2: mainFlow && tit ? OPTIONS.stuck.text2 + " of " + tit  : OPTIONS.stuck.text2
                    // text2: "Would you like to continue?"
                });
                setHelp(true);
            }
            return step;
        });
    }
    const handleDiversionCard = (step) => {
        if (step == 'no') {
            DividerMessage();
            setStepper('basic');
            setInputNextScreen();
            getName('nextButton');
            setHelp(false);
            handleMessages('/stop');

        }
        else if (step == 'tta') {
            window.open('https://wa.link/s5jjl3', '_blank');
            setHelp(false);
        }
        else if (step == 'here') {
            setHelp(false);
            // setAgentHandover(true);
            setStepper('basic');
        }
        else {
            setStepper(step);
            setHelp(false);

        }
    }
    // let faqsLocal = '';
    const handleFAQCard = (step, msg) => {
        setStepper('faqs');
        // console.log("steppss",step)
        // faqsLocal = step;
        setFaqsType({ step: step, msg: msg });
    }
    const handleTTA = (step) => {
        setStepper(step);
        setHelp(false);
        setAgentHandover(true);
    }

    const handleOTPTimeOut = (step) => {
        handleMessages('/stop');
        setStepper(step);


    }
    const getMyLocation = () => {
        const location = window.navigator && window.navigator.geolocation

        if (location) {
            location.getCurrentPosition((position) => {
                handleLocation(position.coords.latitude, position.coords.longitude,0);
                setMyLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                })
            }, (error) => {
                console.log({ latitude: 'err-latitude', longitude: 'err-longitude' })
            })
        }

    }
    // h={stepper=='exp' || stepper=='pin' || stepper == 'otp' || stepper == 'accountinfo' || stepper == 'cnic' || mainFlow == 'resetpin' || stepper == 'accountnumber' || stepper == 'noTransactions' ? '90%' :'85%'}
    return (
        <Box w={{ base: '24px', md: '40px', lg: '400%' }} bgColor="red" className={`formWidget`} style={{ background: stepper == 'menu' ? '#F9F9F9' : 'white' }}>
            {
                stepper == 'basic' || stepper == 'started' ?
                    <MainHeader onClose={setUblWidget} back={false} /> :
                    <MainHeader back={true} onBack={handleBack} onClose={setUblWidget} stepper={stepper} />
            }
            <Box
                className="formWidgetBody"
                id="formWidgetBody"
                overflowY="scroll"
                overflowX="hidden"
                // mt={{ sm: "22%", bs: "22%", lg: "0%" }}
                marginBottom={stepper != 'basic' ? "0%" : "30%"}
            >
                <Flex h='100%' color="red" direction='column' w='100%' background={stepper == 'accountinfo-no' ? 'linear-gradient(25.73deg, #797991 6.77%, #4D8BAA 89.05%)' : 'white'}>
                    {/* bgColor="#f9f9f9" */}
                    {/* history={botReply} */}
                    <Box >
                        {stepper == 'exp' ? <ExpCollector botMessage={botMessage} handleExpDate={handleExpDate} /> : stepper == 'pin' ? <PinCollector botMessage={botMessage} handlePin={handlePin} limit={4} /> : stepper == 'otp' ? <OtpCollector botMessage={botMessage} handleOtp={handleOtp} handleOTPTimeOut={handleOTPTimeOut} /> : stepper == 'accountinfo' ? <BalanceHistory handleAccountCard={handleAccountCard} history={botReply} /> :
                            stepper == 'cnic' ? <CnicCollector botMessage={botMessage} handleCnic={handleCnic} pin={mainFlow == 'resetpin' ? false : false} /> : stepper == 'accountnumber' ? <CardNumber botMessage={botMessage} handleAccountNumber={handleAccountNumber} /> : stepper == 'noTransactions' ? <TransactionHistory handleAccountCard={handleAccountCard} history={DATA.transaction} transLimit={transLimit} /> :
                                stepper == 'NA' ? <Unavilable stepper={setStepper} /> : stepper == 'atmpin' ? <AtmPin handleAtmPin={handleAtmPin} /> : stepper == 'acc_num' ? <PinCollector botMessage={botMessage} handlePin={handlePin} handleAccountNumber={handleAccountNumber} type='account' limit={accNumLimit} /> : stepper == 'faqs' ? <FaqDetails faqs={faqsType} /> :
                                    stepper == 'tta' ? <TalkToAgent setStepper={handleTTA} /> : stepper == 'location' ? <Location stepper={setStepper} location={location} />: stepper == 'directors'?<DirectorsDetails data={directors}/> : null}
                        {mes == 2 ? stepper == 'started' ? <GettingStarted setStepper={setStepper} /> : stepper == 'menu' ? <WidgetMenu setMainFlow={handleFlowMain} setStepper={widgetStep} OptionsMessage={OptionsMessage} urdu={urdu} setUrdu={setUrdu} english={english} setEnglish={setEnglish} handleLang={handleLang} /> : null : null}
                    </Box>
                    <Box className="chatScreen" h="100%" overflowY='scroll' px="5%" mb="5%" display={stepper != 'basic' ? "none" : "1"} >
                        <ChatContainer />
                        {poper ? <Confetti
                            width={width}
                            height={height}
                        /> : null}
                    </Box>
                    {help ?
                        <DiversionMessage
                            options={helpOption}
                            handleCard={handleDiversionCard}
                        />
                        : null}
                    {
                        agentHandover ? <AgentHandover /> : null
                    }
                    {/* <Box position='sticky' bottom={"8%"}> */}
                    {
                       carouselData.length > 1 && stepper == 'basic'?  
                           <CarouselMessages data={carouselData} setDirectors={setDirectors} setStepper = {setStepper}/> 
                           : null
                    }
                    {/* </Box> */}
                    {stepper == 'basic' ? <Box ref={messagesEndRef}></Box> : null}
                    {stepper == 'basic' ? <>{stepper == 'menu' ? null :
                        <IconButton
                            variant='outline'
                            aria-label='Menu'
                            fontSize='20px'
                            icon={<HiMenu color="white" />}
                            borderColor='transparent'
                            bottom={isLessThan800 ? '16%' : "13%"}
                            px="5%"
                            mt='1%'
                            position='absolute'
                            background='linear-gradient(89.78deg, #FF8C79 0.53%, #FF6796 99.79%, rgba(234, 226, 224, 0.7) 99.8%)'
                            borderRadius='50%'
                            ml='3%'
                            onClick={() => handleMenuButton()}
                        />}

                        <Flex direction='row' bottom='0%' borderTopWidth="2px" position='absolute' w='100%' p={["2%", '0%']} borderBottomRadius="2xl" background='white'>
                            <Textarea onClick={() => {
                                if (inputNextScreen) {
                                    setStepper(inputNextScreen)
                                    setHelp(false);
                                }
                            }} value={myMessage} borderColor="transparent" _focus={{ border: "none" }} _hover={{ border: "none" }} textColor="black" placeholder="Type a message here" onChange={(e) => setMyMessage(e.target.value)} onKeyUpCapture={(e) => {
                                if (e.key == 'Enter') {
                                    console.log("ws", myMessage)
                                    if (myMessage !== "" && myMessage !== '\n') {
                                        SimpleMessages('moiz', '', 'right', myMessage);
                                        handleMessages(myMessage);
                                        setStepper("basic");
                                        setMyMessage("");
                                    }
                                }
                            }} />
                            <IconButton
                                variant='outline'
                                colorScheme='teal'
                                aria-label='Call Sage'
                                fontSize='27px'
                                icon={<TbSend color="#FF6E90" />}
                                borderColor='transparent'
                                _hover={{ background: 'transparent' }}
                                onClick={() => {
                                    if (myMessage) {
                                        SimpleMessages('moiz', '', 'right', myMessage);
                                        handleMessages(myMessage);
                                        setStepper("basic");
                                        setMyMessage("");
                                    }
                                }}
                            />
                        </Flex>
                    </> : null}
                </Flex>
            </Box>
        </Box>

    )
}

export default ChatScreenContainer;