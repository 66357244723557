import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image, useMediaQuery } from "@chakra-ui/react";
import { Button, Form, Input, Radio, PageHeader, Select, Spin, Avatar } from "antd";
import { getReply, requestChat, sendMessage } from "../../action/chatWidget";
import {
  Widget,
  addResponseMessage,
  addLinkSnippet,
  toggleWidget,
} from "react-chat-widget";

import { Fab } from "react-tiny-fab";

import { SiWechat } from "react-icons/si";
import { MdWifiCalling3 } from "react-icons/md";
import axios from "axios";
import io from 'socket.io-client';

import "react-chat-widget/lib/styles.css";
import LOGO from "./ublLogo.png";

import { BASE_URL } from "../../constants/api";
import ChatScreenContainer from "./ChatScreenContainer";
import { ChatContainer, ChatWidgetWrapper, DateTime } from "../../components";
import ZARA from "../../assets/Animations/helloTrans.gif";

import "./index.css";


const { Option } = Select;

// const socket = io("ws://d345-2400-adcc-140-6700-3d26-57ec-1f94-b228.ngrok.io", { path: "/subapi/ws/socket.io/", transports: ['websocket', 'polling'] });

const ChatWidget = () => {
  const bottomRef = useRef(null);
  const [isChatbotFormVisible, setIsChatbotFormVisible] = useState(false);
  const [isChatbotModalVisible, setIsChatbotModalVisible] = useState(false);
  const [isCallFormVisible, setIsCallFormVisible] = useState(false);
  const [isCallModal1, setIsCallModal1] = useState(false);

  const [ublWidget, setUblWidget] = useState(false);
  const [isLessThan628] = useMediaQuery('(max-width: 628px)')

  const [isChatbotFormSubmitted, setIsChatbotFormSubmitted] = useState(false);

  const [userName, setUserName] = useState("");
  const [formData, setFormData] = useState({});
  const [value, setValue] = useState(undefined);
  const [date, setDate] = useState(undefined);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [selectedDay, setSelectedDay] = useState(undefined);
  const [selectedTime, setSelectedTime] = useState(undefined);
  const [timeModal, setTimeModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    addResponseMessage(`Welcome to **UBL** your agent is here`);
  }, []);
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  });

  // const handleNewUserMessage = (newMessage) => {
    // socket.emit('chatTms', { message: newMessage, userName: userName });
  // };

  // useEffect(() => {
  //   socket.on('widget', function (msg) {
  //     if (msg) {
  //       var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  //       if (regexp.test(msg)) {
  //         addLinkSnippet({
  //           title: 'Click on link to download',
  //           link: msg,
  //           target: '_blank'
  //         });
  //       }
  //       else {
  //         addResponseMessage(msg);
  //       }
  //     }
  //   })
  // }, [socket])

  const handleForm = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [form] = Form.useForm();

  const handleFormSubmit = async () => {
    setIsChatbotModalVisible(true); // actual chatbot widget
    toggleWidget();
    setIsChatbotFormVisible(false); // chatbot request form
    setIsChatbotFormSubmitted(true);
  };

  const handleCallFormSubmit = async () => {
    setIsCallFormVisible(false);

    let params = {
      subject: formData.subject,
      email: formData.email,
      firstname: formData.firstName,
      cnic: formData.cnic,
      mobile_number: formData.mobile_number,
    };
    if (value == "pick") {
      params = {
        subject: formData.subject,
        email: formData.email,
        firstname: formData.firstName,
        cnic: formData.cnic,
        mobile_number: formData.mobile_number,
        desired_time: selectedTime,
        desired_date: selectedDate,
      };
    }
    const res = await axios
      .post(`${BASE_URL}/initiate_webcallback`, params)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChange = (val) => {
    setValue(val);
    if (val === "pick") setIsLoading(true);
    handleForm("desired_time", null);
    handleForm("desired_date", null);
  };

  useEffect(async () => {
    if (value !== undefined && value === "pick") {
      console.log("ok");
      const res = await axios.get(`${BASE_URL}/available_time`);
      setDate(res.data);
      setSelectedDate(res.data[0].date);
      setSelectedDay(res.data[0].day);
      setTimeSlots(res.data[0].timeslots);
      setIsCallFormVisible(false);
      setTimeModal(true);
      setIsLoading(false);
      setValue(undefined);
    }
  }, [value]);

  useEffect(() => {
    console.log("date=====>", date);
  }, [date]);

  const handleDate = (selectedDate) => {
    const time = date.filter((d) => d.date === selectedDate.date);
    console.log("selected-------->", selectedDate);
    setTimeSlots(time[0].timeslots);
    setSelectedDate(selectedDate.date);
    setSelectedDay(selectedDate.day);
  };

  const handleTimeSlots = (time) => {
    setSelectedTime(time);
    setTimeModal(false);
    setIsCallFormVisible(true);
  };
  const handleWidgetShow = () => {
    // if(ublWidget){
    //   window.YellowMessengerPlugin.show()
    // }else if(isLessThan628 && !ublWidget){
    //   window.YellowMessengerPlugin.hide()
    // }
    setUblWidget(!ublWidget);
  }
  useState(()=>{
    console.log("isLessThan628",isLessThan628)
  },[isLessThan628])
  return (
    <div
      // style={{ backgroundImage: `url(${mainbackground})` }}
      // className="mainBackground"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {ublWidget ?
        <ChatScreenContainer
         setUblWidget={handleWidgetShow} 
         />
        :
        <Image src={ZARA} w='250px' h='180px' position='absolute' bottom='8' right='2' onClick={() => {
          handleWidgetShow();
        }} />
      }
      {/* <Image src={mainbackground} m='auto' w='30%' mt="15%"/> */}

    </div>
  );
};
export default ChatWidget;