import React from "react";
import { Box, Flex, Grid,GridItem, Image, Button, Text } from "@chakra-ui/react";
import { BsArrowRight } from "react-icons/bs"
import AtmCard from "../../assets/atmCard.png";
import SimpleMessages from "./SimpleMessages";
import NextOptions from "./NextOptions";
import ReactDOMServer from "react-dom/server";
import ASSAN from "../../assets/faqimages/assan.png";
import creditcard from "../../assets/faqimages/creditcard.png";
import rda from "../../assets/faqimages/rda.png";
import signature from "../../assets/faqimages/signature.png";
import ublapp from "../../assets/faqimages/ublapp.png";
import urooj from "../../assets/faqimages/urooj.png";
import zara from "../../assets/zara.png";

let id = 0;
let optionsId = 0;

function get(selector, root = document) {
    return root.querySelector(selector);
}
function getid(value) {
    return document.getElementById(value);
}
function formatDate(date) {
    return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, weekday: 'short' });
  }
//   msger-chat
function insertHTML(classname,html) {
    const msgerChat = get(classname);
    msgerChat.insertAdjacentHTML("beforeend", html);
    msgerChat.scrollTop += 500;
}
const Options = ({options}) => {
    return (<Box display='inline-block' overflowX='hidden' mr='2%' id="msg-bubble-options-main" className="msg-bubble-options-main">
        {options?.map((item,index) => { 
            optionsId = optionsId + 1;
            return <Button _hover={{color:'white', bg:'black'}} className="msg-bubble-options" id={item?.title+id} key={index} onClick={()=>alert(item)}>
                {item?.title}
            </Button>
        }
        )}
    </Box>)
    // const msgHTML = `
    //     <Box class="msg-bubble-options" id=${optionsId}>
    //         ${text}
    //     </Box>
    //     `;
    // const msgerChat = get(".option");
    // console.log(msgerChat)
    // msgerChat.insertAdjacentHTML("beforeend", msgHTML);
    // msgerChat.scrollTop += 500;
    // const mtext = getid(optionsId);
    // mtext.onclick = function () { console.log(text); };
    // optionsId = optionsId + 1;
}
const OptionsMessage = (name, options, side, text, func,img,url,handleSeeMore) => {
    let imgUrl = ""
    // options?.map(item=>{
    //     const mtextGen = getid(item?.title+id-1);
    //     if(mtextGen)
    //     mtextGen.remove();
    // })
    const htmlString = ReactDOMServer.renderToString(<Options options={options}/>);
    const imgString = ReactDOMServer.renderToString(<Image src={img} w='400px' h='180px' mb='5%'></Image>);
    const imgNull = ReactDOMServer.renderToString(<Box display='none'></Box>);
    const button = ReactDOMServer.renderToString(<a href={url} target="_blank" rel="noopener noreferrer" style={{marginTop:'10%', fontWeight:'bold', display:'flex', flexDirection:'row', alignContent:'center',alignItems:'center'}}><span style={{marginRight:'3%'}}>Apply Online </span><BsArrowRight/></a>);
    const imgHtml = ReactDOMServer.renderToString(
        <Image className="msg-img" src={zara} ></Image>
        );
    const textBox = ReactDOMServer.renderToString(
        <>
        <Text  fontSize='14px' noOfLines={handleSeeMore?5:null}>{text}</Text>
        {handleSeeMore?<Button id = {`seeMore${id}`} float='right' fontSize='14px' bg='transparent'>See More</Button>:null}
        </>
            );
    const msgHTML = `   
          <Box class="msg ${side}-msg" id=${'option' + id}>
          ${side == 'left'?
          imgHtml
           :imgNull}
          <Box class='option'>
            <Box class="msg-bubble">
            ${img?imgString:imgNull}
                ${textBox}
                ${url?button:imgNull}
            </Box>
                ${htmlString}
                <Box class="msg-info-time-left">${new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, weekday: 'short' })}</Box>
          </Box>
        </Box>
        `;
    insertHTML(".msger-chat",msgHTML);
    options?.map(item=>{
        const mtext = getid(item?.title+id);
        mtext.onclick = function () { 
          const bubble =  getid("msg-bubble-options-main");
          bubble.remove();
         SimpleMessages('moiz', '', 'right', [item?.title]);
         func(item?.payload);
         };
    })
    const seeMore = getid(`seeMore${id}`);
    if(seeMore){ 
    seeMore.onclick = function () {
        handleSeeMore(text,img);
    }
}
    id = id + 1;
    // const msgerChatOptions = get(".options");
    // msgerChatOptions.insertAdjacentHTML("beforeend", msgerChatOptions);
    // console.log("dd",htmlString)
    // options?.map(item=>Options(item));
}

export default OptionsMessage;