import React, { useState } from "react";
import OTPInput  from "otp-input-react";
import { Flex, Text, Box , Image} from "@chakra-ui/react";
import Fade from 'react-reveal/Fade';
import CircleOtp from "../CircleOtp";
import SwipeButton from "../SwipeButton";
import ATMCARD from "../../assets/DebitCard.png";
import PINGIF from "../../assets/ATMPINGIF1.gif";
const AtmPin = ({handleAtmPin,botMessage}) => {
    const [OTP, setOTP] = useState("");
    function handleChange(OTP) {
        setOTP(OTP);
    }
    const handleSubmit = () =>{
        handleAtmPin(OTP);
    }
    return (
        <Flex direction='column' m='auto' py="5%" pl="7%" pr="10%" bgColor="white" style={{ zoom: '90%', minHeight: '100%' }}>
            <Fade right>
            <Box p={3} bgColor="#f9f9f9" borderRadius="10px">
                    <Image src={PINGIF} w='400px' h='200px' />
                </Box>
                <Text fontFamily='Poppins' color='#1B1C1E' mt='5%' mb='2%' ml='5%' mr='5%' fontSize='14px' >{botMessage}</Text>
                <Flex w='100%' mt='15%' px={5} ml="4%">
                    <OTPInput value={OTP} onChange={handleChange} autoFocus OTPLength={4} otpType="number" disabled={false} inputClassName='inputClassName' 
                    onKeyUpCapture = {(e)=>{
                        if(e.key == 'Enter' && OTP.length == 4){
                            handleSubmit();
                        }
                    }}
                    />
                </Flex>
            </Fade>
            <Box position='absolute' bottom='5%' w='85%'>
            <SwipeButton type='otp' func={handleSubmit} disable={OTP?.length == 4?false:true} title='CONTINUE' />
            </Box>
        </Flex>
    )
}

export default AtmPin;