import React, { useEffect, useState, useRef } from "react";
import { Flex, Box, Text, Button, Image } from "@chakra-ui/react";
import QuestionsBox from "./Others/QuestionsBox";
import SIGNATURE from "../assets/faqimages/signature.png";
import ASANIMAGE from "../assets/faqimages/assan.png";
import {DATA} from "../constants";

const FaqDetails = ({faqs}) => {
    const [dataSet, setDataSet] = useState(DATA.faq.ublAsaanAccount);
    const messagesEndRef = useRef(null);
    const [add, setAdd] = useState(-1);
    const [imageType, setImageType] = useState(false);
    useEffect(()=>{
        if(faqs?.step == 'chip'){
            setDataSet( DATA.faq.signature);
            setImageType(false);
        }
        else{
            setDataSet( DATA.faq.ublAsaanAccount);
           setImageType(true);
        }
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    },[faqs])
    // const data = DATA.faq.ublAsaanAccount;
    return (
        <Flex direction='column' m='5%' h='100%'>
            <Box ref={messagesEndRef}></Box>
            <Image src={imageType? ASANIMAGE : SIGNATURE} borderRadius='8px' />
            <Text fontFamily='Poppins' color='#1B1C1E' mt='5%' mb='2%' fontSize='14px'>Q: {dataSet[0].question}</Text>
            <Box border='1px solid #DADADA' borderRadius='11px' p='5%'>
                <Text fontFamily='Poppins' color='#888888' fontSize='12px'>{dataSet[0].answer}</Text>
            </Box>
            <Text fontFamily='Poppins' color='#1B1C1E' mt='5%' fontSize='18px'>Most asked questions</Text>
            {dataSet.map((item,index) =>
                <QuestionsBox data={item} id={index} add={add} setAdd={setAdd}/>
            )}
        </Flex>
    )
}

export default FaqDetails;