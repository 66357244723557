import React from "react";
import { Box, Flex, Grid, GridItem, Image } from "@chakra-ui/react";
import Fade from 'react-reveal/Fade';

import AtmCard from "../../assets/atmCard.png";
import SimpleMessages from "./SimpleMessages";
import NextOptions from "./NextOptions";
import ReactDOMServer from "react-dom/server";
import ZARASTANDING from '../../assets/zarastand.svg';

let id = 0;
let optionsId = 0;

function get(selector, root = document) {
    return root.querySelector(selector);
}
function getid(value) {
    return document.getElementById(value);
}
const Options = ({ options, handleCard }) => {
    return (<Grid templateColumns='repeat(2, 1fr)' gap={2} id="msg-bubble-options-main" className="msg-bubble-options-main">
        {options?.map((item, index) => {
            optionsId = optionsId + 1;
            return <GridItem className="msg-bubble-options" id={item?.title + id} key={index} onClick={() => handleCard(item?.payload)}>
                {item?.title}
            </GridItem>
        }
        )}
    </Grid>)
}
const DiversionMessage = ({options, handleCard }) => {

    return (
            <Box className="msg left-msg" mb='5%'>
                <Box className='option'>
                    <Flex direction='row' ml='5%' mr='7%'>
                            <Box w='25%'>
                                <Image src={ZARASTANDING} w='100%' h='100%' m='0%' objectFit='cover' />
                            </Box>
                        <Fade right>
                            <Flex direction='column' bg='#F6F6F6' p='10px' borderRadius='10px' >
                                <Box className="msg-text" textColor='#51535780' mb="5%">{options?.text1}</Box>
                                <Box className="msg-text" textColor='#FF6E90'>{options?.text2}</Box>
                                <Options options={options?.options} handleCard={handleCard}/>
                            </Flex>
                        </Fade>
                    </Flex>
                </Box>
            </Box>
    )
}

export default DiversionMessage;