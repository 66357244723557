import React, { useState } from "react";
import { Flex, Box, Image, Text, Input, NumberInput } from "@chakra-ui/react";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { Calendar, DatePicker } from 'antd';
import Fade from 'react-reveal/Fade';
import ATMCARD from "../../assets/DebitCard.png";
import debit from "../../assets/debit.png";
import EXPGIF from "../../assets/Animations/expiry.gif";
import SwipeButton from "../SwipeButton";
const ExpCollector = ({ handleExpDate, botMessage }) => {
    const [dateNew, setDateNew] = useState();
    const [pin, setPin] = useState("")
    const [date, setDate] = useState("")
    const [calendar, setCalendar] = useState(false);
    const onChange = (name, value) => {
        if (name == 'date') {
            setDate(value.replace('-', '/'))
            // setDate('00/22')
        }
        else if (name == 'pin') {
            setPin(value)
        }
    };
    const handleSubmit = () => {
        handleExpDate(date)
    }
    return (
        <>
            <Flex direction='column' m='auto' py="5%" pl="7%" pr="10%" bgColor="white" style={{ zoom: '90%', minHeight: '100%' }}>
                <Fade right>
                    <Box p={3} bgColor="#f9f9f9" borderRadius="10px">
                        <Image src={EXPGIF} w='400px' h='200px' />
                    </Box>
                    <Text fontFamily='Poppins' color='#1B1C1E' ml='5%' my="3%" fontSize='14px'>{botMessage}</Text>
                    <Flex direction='column'
                        onKeyUpCapture={(e) => {
                            if (e.key == 'Enter' && date) {
                                handleSubmit();
                            }
                        }}>
                        <Box ml='5%' onClick={() => setCalendar(!calendar)}>
                            <Input borderRadius='5px' placeholder="Select date" color='#515357' _placeholder={{ opacity: 1, color: '#515357', fontSize: '14px' }} p='5px' disabled={true} value={date} style={{ border: '0.5px solid rgba(81, 83, 87, 0.5)' }} />
                        </Box>
                        {
                            calendar ? <Box className="site-calendar-demo-card" mt='1%' w='100%' ml='5%' my="3%" borderRadius="5px">
                                <Calendar mode="year" style={{ width: '100%' }} fullscreen={false} value={dateNew} onSelect={(e) => { onChange('date', e.format('MM-YY')); setDateNew(e) }}
                                // monthCellRender={(e)=><Box bg='red' onClick={()=>console.log("sa",e)}>
                                //     mo
                                // </Box>}
                                />
                                {/* <DatePickerComponent id="datepicker" start="Decade" placeholder="Enter date"/> */}
                                {/* <DatePicker/> */}
                                <Box >
                                    <SwipeButton disable={date ? false : true} func={handleSubmit} title='CONTINUE' />
                                </Box>
                            </Box> :
                                null
                        }

                    </Flex>
                </Fade>
            </Flex>
            {
                calendar ? null : <Box position='absolute' bottom='5%' w='85%' ml='7%'>
                    <SwipeButton disable={date ? false : true} func={handleSubmit} title='CONTINUE' />
                </Box>
            }
        </>

    )
}

export default ExpCollector;