import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./assets/index.css";
import App from "./App";
import configureStore from "./store/configureStore";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// import "slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";
const store = configureStore({});
  ReactDOM.render(<MuiPickersUtilsProvider utils={DateFnsUtils}>
<Provider store={store}>
    <App />
  </Provider>
  </MuiPickersUtilsProvider>, document.getElementById("root"));
serviceWorker.register();
