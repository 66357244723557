
import Api from "../lib/api";
import * as types from "./types";
require('dotenv').config()
function getReplyAction(reply) {
    return {
        type: types.GET_REPLY,
        reply,
    };
}

function getRequestChat(reply) {
    return {
        type: types.GET_REQUEST_CHAT,
        reply,
    };
}

export const getReply = (message, userName) => {
    const param = {
        sender: `${userName}`,
        message: message
    }
    return (dispatch) => {
        // dispatch(setIsLoading(true));
        Api.post(`${process.env.REACT_APP_BASE_URL}/webhooks/rest/webhook`, param)
            .then((resp) => {
                console.log("responses from get replies", resp[0]?.text)
                resp?.map(item => {
                    dispatch(getReplyAction(item?.text));
                })
                // dispatch(setIsLoading(false));
            })
            .catch((err) => {
                console.log("resp from api is error", err)
                // dispatch(setIsLoading(false));
            });
    };
}

export const requestChat = (message, userName, fName, lName, formData) => {
    const param = {
        nickname: userName,
        // firstName: fName,
        // lastName: lName,
        ...formData
    }
    var formBody = [];
    for (var property in param) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(param[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return (dispatch) => {
        // dispatch(setIsLoading(true));
        Api.postMultiForm(`${process.env.REACT_APP_GENSYIS_URL}/genesys/2/chat/request-chat/`, formBody)
            .then(async (resp) => {
                console.log("responses from requestChat", resp);
                dispatch(getRequestChat({
                    nickname: userName,
                    userId: resp?.userId,
                    chatId: resp?.chatId,
                    secureKey: resp?.secureKey,
                    alias: resp?.alias,
                }));
            //     const response = await fetch('http://localhost:8000/api/v1/gyensis/insertRecord', {
            //         method: 'POST', // *GET, POST, PUT, DELETE, etc.
            //         mode: 'cors', // no-cors, *cors, same-origin
            //         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            //         credentials: 'same-origin', // include, *same-origin, omit
            //         headers: {
            //             'Content-Type': 'application/json'
            //             // 'Content-Type': 'application/x-www-form-urlencoded',
            //         },
            //         redirect: 'follow', // manual, *follow, error
            //         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            //         body: JSON.stringify({
            //             nickname: userName,
            //             userId: resp?.userId,
            //             chatId: resp?.chatId,
            //             secureKey: resp?.secureKey,
            //             alias: resp?.alias
            //         }) // body data type must match "Content-Type" header
            //     });
            //     console.log("responses from db added data", response.json());
            //     // dispatch(setIsLoading(false));
            })
            .catch((err) => {
                console.log("resp from api is error", err)
                // dispatch(setIsLoading(false));
            });
    };
}

export const sendMessage = (data, message) => {
    const param = {
        userId: data?.userId,
        alias: data?.alias,
        secureKey: data?.secureKey,
        message: message
    }
    var formBody = [];
    for (var property in param) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(param[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return (dispatch) => {
        // dispatch(setIsLoading(true));
        //     Api.postMultiForm(`https://webchat.soneribank.com:8443/genesys/2/chat/customer-support/${data?.chatId}/send/`, formBody)
        //         .then((resp) => {
        //             console.log("responses from send", resp)
        //             // dispatch(setIsLoading(false));
        //         })
        //         .catch((err) => {
        //             console.log("resp from api is error", err)
        //             // dispatch(setIsLoading(false));
        //         });
        
        // dispatch(getRequestChat({
        //     nickname: data?.nickname,
        //     userId: data?.userId,
        //     chatId: data?.chatId,
        //     secureKey: data?.secureKey,
        //     alias: data?.alias
        // }));

        fetch(`${process.env.REACT_APP_GENSYIS_URL}/genesys/2/chat/customer-support/${data?.chatId}/send/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                userId: data?.userId,
                alias: data?.alias,
                secureKey: data?.secureKey,
                message: message
            })
        })
            .then(res => {
                // Do stuff with the result
            });

    };
}

