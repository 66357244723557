import React, { useState } from "react";
import OTPInput from "otp-input-react";
import { Flex, Text, Box, Button } from "@chakra-ui/react";
import Fade from 'react-reveal/Fade';
import CircleOtp from "./CircleOtp";
import VIDEOFILE from "../assets/ads/carLoan.mp4"
const TalkToAgent = ({setStepper}) => {

    return (
        <Flex direction='column' gap='10px' minHeight='100%' pt='5%' bg='white' style={{ zoom: '90%' }} mb='10%'>
            <Fade right>
                <video autoPlay loop width="100%" height="240" controls onClick={()=>window.open('https://apple.co/3SvsQ9Q')}>
                    <source src={VIDEOFILE} type="video/mp4" />
                </video>
                <Flex direction='row' alignItems='center' w='90%' p='2%' borderRadius='10px' m='2%' ml='5%' background='#FFFFFF' border='1px solid #DADADA'>
                    <Flex direction='column' m='5%'>
                        <Text textColor='#515357'>Estimated Time</Text>
                        <Text p='10px' bgGradient ='linear(to-l, rgba(255, 103, 150, 1),rgba(255, 140, 121, 1),)' textColor='white' border='1px solid #FF8C79' >You are fourth in line!</Text>
                    </Flex>
                    <Box ml='auto' mr='5%'>
                        <CircleOtp setStepper={setStepper} time={240} size={80} fontSize='31.4847' />
                    </Box>
                </Flex>
            </Fade>
        </Flex>
    )
}

export default TalkToAgent;