import React, { useEffect, useRef, useState } from "react";
import { Flex, Text, Box } from "@chakra-ui/react";
import Fade from 'react-reveal/Fade';
import { Button } from 'ui-neumorphism'
import 'ui-neumorphism/dist/index.css'
import SwipeButton from "./SwipeButton";
import TansactionButton from "./TransactionButton";

const BalanceHistory = ({ handleHistory, history, handleAccountCard }) => {
    const refBalance = useRef();
    const [show, setShow] = useState(false);

    useEffect(()=>{
        refBalance.current?.scrollIntoView({ behavior: "smooth" })
    },[show]);
    return (
        <Flex direction='column' minHeight='100%' m='5%' overflowY="scroll">
            {/* <Text color='#515357' fontSize='14px'>Hello,</Text>
            <Text color='#515357' fontSize='18px' fontWeight='bold'>Sheraz</Text> */}
            <Button bgColor='white'  style={{ width: show?'100px':'200px', height: show?'100px':'200px', fontSize:'8px', marginLeft: show?'35%':'20%', marginBottom:'12%', borderRadius: '100px', outlineStyle: 'initial', boxShadow: '13px 20px 40px -7px rgba(255,107,147,0.76)'}} onClick={() => setShow(!show)}>
               <span style={{fontSize:show?'10px':'14px'}}>{show? "Hide Balance" :"Show Balance"}</span>
            </Button>
            {
                show ?
                    <Fade bottom >
                        <Flex ref = {refBalance} direction='column' alignItems='flex-start' background='#F9F9F9' borderRadius='10px' p='5%' mx="5%" mt="10%" bgGradient='linear(to-l,  rgba(255, 103, 150, 1),rgba(255, 140, 121, 1),)' >
                            <Text fontFamily='Poppins' fontSize='14px' color='white'>
                                {history?.title ? history?.title : 'Your balance is'}
                            </Text>
                            <Text fontFamily='Poppins' fontSize='24px' color='white' fontWeight='semibold'>
                                {`Rs. ${history?.amount ? history?.amount : '100,000'}`}
                            </Text>
                            <Text fontFamily='Poppins' fontSize='16px' color='white' mt='5%'>
                                {`Account # ${history?.account ? history?.account : '1217XXXXXXXX2699'}`}
                            </Text>
                        </Flex>
                    </Fade>
                    :
                    null
            }
            <Flex direction='row' position='absolute' bgColor='white' w="100%" bottom='0.05%' left="0.25%" borderBottomRadius="10px" px="7%" py='2%' right="0.01%" >
                <Box w="100%">
                    <TansactionButton type='history' func={handleAccountCard} disable={false} title='DONE' />
                </Box>
            </Flex>
        </Flex>
    )
}

export default BalanceHistory;