export const USER_REGISTER = "USER_REGISTER";
export const IS_LOADING = "IS_LOADING";
export const STORE_TOKEN = "STORE_TOKEN";
export const GET_INTENTS = "GET_INTENTS";
export const GET_ALL_INTENTS = "GET_ALL_INTENTS";
export const GET_RESPONSES = "GET_RESPONSES";
export const SET_TRAINING_STATUS = "SET_TRAINING_STATUS";
export const GET_STORIES = "GET_STORIES";
export const GET_CONFIG = "GET_CONFIG";
export const GET_RULES = "GET_RULES";
export const GET_REPLY = "GET_REPLY";
export const GET_REQUEST_CHAT = "GET_REQUEST_CHAT";