import React, { useState } from "react";
import { Flex, Text, Box, Image } from "@chakra-ui/react";
import Flip from 'react-reveal/Flip';
import SwipeButton from "./SwipeButton";
const DirectorsDetails = ({stepper,data,setStepper}) => {
    const handleSubmit = () => {
        stepper('basic')
    }
    return (
        <Flex direction='column' minHeight='100%' m='5%'>
            <Flip right>
                <Box bg='#F9F9F9'fontFamily='Poppins' color='#7B7B7B'p='5%' borderRadius='10px'>
                    {data?.image?<Image src={data?.image} w='400px' h='180px' mb='5%'></Image>:null}
                    <Text fontSize='14px' fontWeight='bold'>{data?.name}</Text>
                    <Text fontSize='12px' mb='5%'>{data?.post}</Text>
                    <Text  fontSize='14px'>{data?.info}</Text>
                </Box>
            </Flip>
            {/* <Box position='absolute' bottom='5%' w='85%' ml='7%'>
                <SwipeButton func={handleSubmit} title='CONTINUE' />
            </Box> */}
        </Flex>
    )
}

export default DirectorsDetails;