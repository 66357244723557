import React, { useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { GrFormAdd } from "react-icons/gr";

const QuestionsBox = ({ data, id, setAdd, add }) => {
    console.log("ff", data)
    return <Box mt='5%' onClick={() => {
        if(add == id){
            setAdd(-1)
        }
        else{
            setAdd(id)
        }
        }}>
        <Flex background='#F8F8F8' p='5%' borderRadius='8px' direction='row' >
                <Text textColor='#515357' fontSize='14px' mr='1%'>
                    {`Q${id+1}: `}
                </Text>
            <Text textColor='#515357' fontSize='14px' w='80%'>
                {data?.question}
            </Text>
            <Box ml='auto' fontSize='24px' textColor='#515357'>
                <GrFormAdd />
            </Box>
        </Flex>
        {add == id ?
            data?.answer.split('\n').map(item => <Text textColor='#888888' mt='2%' fontSize='12px' m='2%'>{item}</Text>)
            : null}
    </Box>
}

export default QuestionsBox;