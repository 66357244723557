import React, { useEffect, useState } from "react";
import { Flex, Box, Image, Text, NumberInputField, NumberInput } from "@chakra-ui/react";
import Fade from 'react-reveal/Fade';
import InputMask from 'react-input-mask'
import SwipeButton from "../SwipeButton";
import CNIC from "../../assets/Animations/nic.gif";

const CnicCollector = ({ handleCnic, pin, botMessage }) => {
    const [cnic, setCnic] = useState("");
    const [len, setLen] = useState();
    const [gif, setGif] = useState('')
    useEffect(() => {
        if (pin) {
            setLen(4);
        }
        else {
            setLen(16);
        }
        reloadGif()
    }, [])
    const handleSubmit = () => {
        handleCnic(cnic)
    }
    const reloadGif = () => {
        setGif('')
        setTimeout(() => {
            setGif(CNIC)
        }, 0)
    }
    return (
        <Flex direction='column' m='auto' py="5%" pl="7%" pr="10%" bgColor="white" style={{ zoom: '90%', minHeight: '100%' }}>
            <Fade right>
                <Box p={3} bgColor="#f9f9f9" borderRadius="10px">
                    <Image src={gif} w='400px' h='200px' borderRadius="16px" />
                </Box>
                <Text fontFamily='Poppins' color='#1B1C1E' mt='5%' mb='2%' ml='5%' mr='5%' fontSize='14px'>{botMessage}</Text>
                <NumberInput>
                    {/* <NumericFormat format="#####-#######-#" allowEmptyFormatting mask="_" placeholder='xxxxx-xxxxxxx-x' onChange={(e) => console.log("eee", e.target.value)} /> */}
                    <InputMask mask="99999-9999999-9" placeholder="XXXXX-XXXXXXX-X" maskChar="" onChange={(e) => {
                        setCnic(e.target.value);
                        if(e.target.value[e.target.value.length-1] != '_'){
                            setLen(15);
                        }
                        else{
                            setLen(0);
                        }
                        }}
                        onKeyUpCapture = {(e)=>{
                            if(e.key == 'Enter' && cnic.length == len){
                                handleSubmit();
                            }
                        }}
                         style={{ border: '0.5px solid rgba(81, 83, 87, 0.5)', opacity:'0.4', borderRadius:'5px', marginLeft:'5%', width:'90%', color:'black', padding:'10px' }}/>
                    {/* <NumberInputField opacity='0.4' borderRadius='5px' placeholder={pin ? "xxxx" : "xxxxxxxxxxxxx"} ml='5%' w='90%' color='black' _placeholder={{ opacity: 1, color: '#515357', fontSize: '14px' }} _focus={{ border: 'none' }} p='8px' maxLength={len} onChange={(e) => setCnic(e.target.value)} style={{ border: '0.5px solid rgba(81, 83, 87, 0.5)' }} /> */}
                </NumberInput>
            </Fade>
            <Box position='absolute' bottom='5%' w='85%'>
                <SwipeButton func={handleSubmit} disable={cnic.length == len ? false : true} title='CONTINUE' />
            </Box>
        </Flex>
    )
}

export default CnicCollector;