import React, { useEffect } from "react";
import { Flex, Text, Box, Image } from "@chakra-ui/react"
import Maps from "./Others/Maps";
import SwipeButton from "./SwipeButton";
import UBL from "../assets/ublLogo2.png";

export default function Location({stepper,location}) {
    // useEffect(()=>{

    // },[location])
    const handleSubmit = () => {
        stepper('basic')
    }

    return (
        // Important! Always set the container height explicitly
        <Box>
            <div style={{ height: '45vh', width: '100%' }}>
               <Maps location={location}/>
            </div>
            <Flex direction='column' m='5%'>
                <Flex mb='2%' alignItems='center'>
                    <Image src={UBL} width='50px' mr='2%'/>
                    <Text color="#515357">{location?.branch_name}</Text>
                    {/* <Flex direction='column'>
                        <Text color="#515357">{location?.branch_name}</Text>
                        <Text color="rgba(81, 83, 87, 0.5)">Jinnah Avenue</Text>
                    </Flex> */}
                </Flex>
                <Flex alignItems='center'>
                    <Text mr='2%' color="#515357">
                        Address:
                    </Text>
                    <Text color="rgba(81, 83, 87, 0.5)">
                        {location?.address}
                    </Text>
                </Flex>
                <Box position='absolute' bottom='2%' w='85%' ml='2%'>
                    <SwipeButton func={handleSubmit} title='CONTINUE' />
                </Box>
            </Flex >
        </Box>
    );
}