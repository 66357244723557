import React, { useState } from "react";
import { Col } from 'antd';
import { Flex, Box, Text, Image } from "@chakra-ui/react";

const MenuBox = ({setMainFlow, title, flow, Icon}) => {
    const [borderColor, setBorderColor] = useState('rgba(255, 103, 150, 1)');
    const [textColor, setTextColor] = useState('#515357');
    const [style, setStyle] = useState({});
   return <Col span={8} onClick={() => { setMainFlow(flow) }} >
        <Flex direction='column' px='5%' py="20%" alignItems='center' background='#FFFFFF' borderRadius='10.184px' boxShadow='rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' h='120px' _hover={{bgGradient:'linear(to-l, rgba(255, 103, 150, 1),rgba(255, 140, 121, 1),)'}} onMouseLeave={()=>{setBorderColor('rgba(255, 103, 150, 1)');setTextColor('#515357');setStyle({})}} onMouseEnter={()=>{setBorderColor('white');setTextColor('white'); setStyle({transform:'rotateY(360deg)', transition:'transform 1.5s'})}}>
            <Box borderRadius="full" borderWidth="2px" borderColor={borderColor} textColor={borderColor} p={3} fontSize='24px' transform={style?.transform} transition={style?.transition}>
                <Icon />
            </Box>
            <Text color={textColor} align="center" mt="10%" fontSize="xs">{title}</Text>
        </Flex>
    </Col>
}

export default MenuBox;